import { BaseViewComponent, PageView, getViewFromUrl } from './base-view.component';
import { CallbackComponent } from './callback/callback.component';
import { ErrorComponent } from './error/error.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { OfficerComponent } from './officer/officer.component';
import { ProfileComponent } from './profile/profile.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UserComponent } from './user/user.component';
import { UsersComponent } from './users/users.component';

export {
  BaseViewComponent,
  CallbackComponent,
  UnauthorizedComponent,
  LoginComponent,
  LogoutComponent,
  HomeComponent,
  OfficerComponent,
  ProfileComponent,
  UsersComponent,
  UserComponent,
  ErrorComponent,
  PageView,
  getViewFromUrl
};

