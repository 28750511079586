import { DOCUMENT } from '@angular/common';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EMPTY, Subject, filter, fromEvent, merge, of, share, switchMap, tap, throttleTime, timer } from 'rxjs';
import { TranslatePipe } from '@app/core/i18n';
import { environment } from '@environments/environment';
import { OdsDialog, OdsDialogData } from '@shared/components/ods';
import { UserService } from './user.service';

const SESSION_TIMESTAMP = 'sessionTimestamp';

const THROTTLE_DELAY = 1000;

@Injectable({ providedIn: 'root' })
export class SessionTimeoutService {
  public readonly eventNames: readonly string[] = 'DOMMouseScroll keydown mousedown mousemove mousewheel scroll touchstart touchmove'.split(' ');

  public readonly sessionIdleTimeoutInMins = environment.sessionIdleTimeoutInMins;

  public readonly sessionIdleAlertLeadTimeInMins = environment.sessionIdleAlertLeadTimeInMins;

  readonly #dialog = inject(MatDialog);

  readonly #translate = inject(TranslatePipe);

  readonly #userService = inject(UserService);

  readonly #router = inject(Router);

  readonly #document = inject(DOCUMENT);

  readonly #destroyRef = inject(DestroyRef);

  readonly #timerExtend$ = new Subject<void>();

  readonly #activity$ = merge(
    of(true),
    ...this.eventNames.map(eventName => fromEvent(this.#document, eventName, { passive: true })),
    fromEvent(this.#document.defaultView, 'resize', { passive: true }),
    this.#timerExtend$.asObservable()
  ).pipe(
    throttleTime(THROTTLE_DELAY),
    tap(() => localStorage.setItem(SESSION_TIMESTAMP, `${Date.now()}`)) // Notify other tabs via storage
  );

  readonly #activityFromAllTabs$ = merge(
    this.#activity$,
    fromEvent<StorageEvent>(this.#document.defaultView, 'storage').pipe(
      filter(event => event.storageArea === localStorage && event.key === SESSION_TIMESTAMP)
    )
  );

  readonly #timerReset$ = this.#userService.loggedIn$.pipe(
    switchMap(isLoggedIn => isLoggedIn ? this.#activityFromAllTabs$ : EMPTY),
    share()
  );

  #sessionIdleAlertDialog: MatDialogRef<OdsDialog, string>;

  constructor() {
    this._initSessionIdleAlert();
    this._initSessionIdleTimeout();
  }

  private _initSessionIdleTimeout(): void {
    this.#timerReset$.pipe(
      switchMap(() => timer(this.sessionIdleTimeoutInMins * 60000)),
      switchMap(() => {
        this.#userService.revokeAccess();

        return this._openSessionExpiredDialog().afterClosed();
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(dialogResult => {
      this.#dialog.closeAll();
      this.#router.navigate([dialogResult === 'Yes' ? '/login' : '/logout']);
    });
  }

  private _initSessionIdleAlert(): void {
    this.#timerReset$.pipe(
      switchMap(() => timer((this.sessionIdleTimeoutInMins - this.sessionIdleAlertLeadTimeInMins) * 60000)),
      switchMap(() => this.#translate.transform('app.session.warning', [this.sessionIdleAlertLeadTimeInMins])),
      switchMap(content => this._openSessionIdleAlertDialog(content).afterClosed()),
      filter(Boolean),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(() => {
      this.#timerExtend$.next();
      console.log('Session extended', this.#userService.loginUser);
    });
  }

  private _openSessionExpiredDialog(): MatDialogRef<OdsDialog, string> {
    this.#sessionIdleAlertDialog?.close();
    const sessionExpiredDialog = this.#dialog.open<OdsDialog, OdsDialogData, string>(OdsDialog, {
      data: {
        title: 'app.signedout',
        content: 'app.session.expired',
        yes: 'menu.login.text',
        no: 'common.text.close'
      }
    });

    return sessionExpiredDialog;
  }

  private _openSessionIdleAlertDialog(content: string): MatDialogRef<OdsDialog, string> {
    this.#sessionIdleAlertDialog?.close();
    this.#sessionIdleAlertDialog = this.#dialog.open<OdsDialog, OdsDialogData, string>(OdsDialog, {
      data: {
        title: 'common.text.confirm',
        content,
        yes: 'common.text.yes'
      }
    });

    return this.#sessionIdleAlertDialog;
  }
}
