import { API_ENDPOINTS } from "@/src/environments/environment";
import { Injectable } from "@angular/core";
import { NotificationService } from "@app/core/notifications";
import cloneDeep from 'lodash/cloneDeep';
import { Observable, catchError, map, of, tap, switchMap, throwError } from "rxjs";
import { UserApiService, UserService } from ".";

@Injectable({ providedIn: 'root' })
export class UserDispatcher {
  constructor(
    private userApiService: UserApiService,
    private userService: UserService,
    private notificationService: NotificationService
  ) { }

  get users$() {
    return this.userService?.list$;
  }

  get loginUser$() {
    return this.userService?.loginUser$;
  }

  get userId() {
    return this.userService?.userId;
  }

  get authorizedServices() {
    return this.userService.authorizedServices;
  }

  get notifications$() {
    return this.notificationService?.list$;
  }

  clearNotifications() {
    return this.notificationService?.clear();
  }

  addUserSuccessNotification(details) {
    return this.notificationService?.addSuccess(
      details);
  }

  addDeleteSuccessNotification(details) {
    return this.notificationService?.addSuccess({
      ...details,
      title: 'submission.notification.delete.success.title.text',
      content: 'submission.notification.delete.success.content.text'
    });
  }

  addUserErrorNotification(details) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.create.error.title.text',
      content: 'submission.notification.create.error.content.invalidFormFields.text'
    });
  }

  addDeleteErrorNotification(details) {
    return this.notificationService?.addError({
      ...details,
      title: 'submission.notification.delete.error.title.text',
      content: 'submission.notification.delete.error.content.text'
    });
  }

  loadAll(isAppRequest = false, searchFirstName = '', searchLastName = '', searchEmail = '', searchEmployeeID = ''): Observable<any[]> {
    return this.userApiService?.getAll(isAppRequest).pipe(
      // return this.userApiService?.getAllByAgency(this.userService.userAgency, isAppRequest).pipe(
      map((users: any[]) => {
        if (!this.userService) return [];

        return users.filter((u: any) => {
          const matchesFirstName = !searchFirstName || (u.firstName && u.firstName.toUpperCase().includes(searchFirstName.toUpperCase()));
          const matchesLastName = !searchLastName || (u.lastName && u.lastName.toUpperCase().includes(searchLastName.toUpperCase()));
          const matchesEmail = !searchEmail || (u.loginEmail && u.loginEmail.toUpperCase().includes(searchEmail.toUpperCase()));
          const matchesEmployeeID = !searchEmployeeID || (u.badgeNo && u.badgeNo.toUpperCase().includes(searchEmployeeID.toUpperCase()));

          return matchesFirstName && matchesLastName && matchesEmail && matchesEmployeeID;
        });
      })
    );
  }

  load(isAppRequest = false, searchFirstName = '', searchLastName = '', searchEmail = '', searchEmployeeID = ''): Observable<any[]> {
    // return this.userApiService?.getAll(isAppRequest).pipe(
    return this.userApiService?.getAllByAgency(this.userService.userAgency, isAppRequest).pipe(
      map((users: any[]) => {
        if (!this.userService) return [];

        return users.filter((u: any) => {
          const matchesFirstName = !searchFirstName || (u.firstName && u.firstName.toUpperCase().includes(searchFirstName.toUpperCase()));
          const matchesLastName = !searchLastName || (u.lastName && u.lastName.toUpperCase().includes(searchLastName.toUpperCase()));
          const matchesEmail = !searchEmail || (u.loginEmail && u.loginEmail.toUpperCase().includes(searchEmail.toUpperCase()));
          const matchesEmployeeID = !searchEmployeeID || (u.badgeNo && u.badgeNo.toUpperCase().includes(searchEmployeeID.toUpperCase()));

          return matchesFirstName && matchesLastName && matchesEmail && matchesEmployeeID;
        });
      })
    );
  }

  loadById(id, isAppRequest = false): Observable<any> {
    const toBeLoaded = this.userService.list.find(s => s?.id === parseInt(id));
    const requestId = API_ENDPOINTS.USER_GET.indexOf(`{userId}`) === -1 ? toBeLoaded?.id : toBeLoaded?.userId;
    // return this.userApiService?.get(requestId || id, isAppRequest).pipe(
    return this.userApiService.getByEmail(toBeLoaded?.loginEmail, isAppRequest).pipe(
      map((user: any) => {
        if (!this.userService) return null;
        this.userService.loginUser = { ...user };
        return this.userService?.loginUser;
      })
    );
    // return this.load(isAppRequest).pipe(
    //   map((users) => {
    //     if (!this.userService) return null;
    //     const userId = requestId || id;
    //     const user = users.find(u => u.id === parseInt(userId));
    //     if (!user) return null;
    //     this.userService.selected = { ...user };
    //     return this.userService?.selected;
    //   })
    // );
  }

  findById(id, isAppRequest?) {
    return this.userApiService.get(id, isAppRequest);
  }

  save(payload, opts?, isAppRequest?) {
    const userSaveRequest = () => {
      if (payload?.id) {
        return this.userApiService.update(payload, isAppRequest);
      } else {
        return this.userApiService.getByEmail(payload?.loginEmail, isAppRequest).pipe(
          switchMap((user: any) => {
            if (!user?.id) {
              return this.userApiService.create(payload, isAppRequest);
              // return user;
            }
            this.notificationService?.clear();
            let errors = "User with login email exists"
            this.addUserErrorNotification({ model: 'User', from: opts.from, data: errors });
            
            return throwError({ error: ['User already exists' ] }); // Return the user observable if it exists

          })
        );
      }
    }
  
    return userSaveRequest().pipe(
      tap(user => {
        this.userService?.add(user);
        this.notificationService?.clear();
        this.addUserSuccessNotification({ model: 'User', from: opts.from, title: opts.msg, content:'' });
      }),
      catchError(e => {
        this.notificationService?.clear();
        let errors = Array.isArray(e.error) ? e.error : Object.keys(e.error?.errors).map(errKey => e.error.errors[errKey]);
        this.addUserErrorNotification({ model: 'User', from: opts.from, data: errors });
        return of(e);
      })
    );
  }
}