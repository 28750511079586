import { Component } from '@angular/core';
import { AppService } from '@app/core';
import { UserService } from '@app/core/auth';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})
export class CallbackComponent {

  constructor(private userService: UserService, private appService: AppService) { }

  ngOnInit(): void {
    this.userService.loggedIn$.subscribe(loggedIn => loggedIn ?
      this.appService.goToHomePage() :
      this.appService.goToUnauthorizdPage()
    );
  }
}