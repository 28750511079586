<div class="container-full-width">
  <ng-container *ngIf="view.isReady">

    <!-- header -->
    <div class="ontario-row full-width profile-header">
      <div
        class="ontario-columns ontario-large-11 ontario-medium-11 ontario-small-11 ontario-padding-left-0-! profile-name">
        <div class="ontario-form-group ontario-margin-bottom-0-!">
          <h1 class="ontario-margin-bottom-0-!">{{ formCtrl('firstName').value | titlecase }} {{
            formCtrl('lastName').value | titlecase}}</h1>
        </div>
      </div>

      <div
        class="ontario-columns ontario-large-1 ontario-medium-1 ontario-small-1 ontario-padding-left-0-! profile-edit-button">
      </div>
    </div>
    <hr class="ontario-margin-top-16-!" />

    <!-- form input errors from server -->
    <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

    <!-- form input success from server -->
    <ng-container *ngIf="shouldShowSuccess" [ngTemplateOutlet]="successNotificationTemplate"></ng-container>

    <!-- <div *ngIf="user?.form" class="ontario-row full-width">
      <ng-container *ngTemplateOutlet="formTemplate;context:{editing:true}"></ng-container>
    </div> -->




    <div class="ontario-row full-width">
      <div id="describedBy" hidden>{{ "profile.pageDescription.text" | translate | async }}</div>
      <form novalidate [formGroup]="user.form" class="ontario-row full-width">

        <div class="ontario-row full-width">
          <!-- email -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('loginEmail').id }}">
                <span name>{{ field('loginEmail').label | translate | async }}</span>
              </odsf-label>

              <odsf-input formCtrlId="{{ field('loginEmail').id }}" formCtrlName="{{ field('loginEmail').name }}"
                [formCtrl]="formCtrl('loginEmail')" [required]="field('loginEmail').required" readonly="true" >
              </odsf-input>
            </div>
          </div>
        </div>

        <div class="ontario-row full-width">
          <!-- first name -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('firstName').id }}">
                <span name>{{ field('firstName').label | translate | async }}</span>
              </odsf-label>

              <odsf-input formCtrlId="{{ field('firstName').id }}" formCtrlName="{{ field('firstName').name }}"
                [formCtrl]="formCtrl('firstName')" [required]="field('firstName').required" readonly="true" >
              </odsf-input>
            </div>
          </div>

          <!-- last name -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('lastName').id }}">
                <span name>{{ field('lastName').label | translate | async }}</span>
              </odsf-label>

              <odsf-input formCtrlId="{{ field('lastName').id }}" formCtrlName="{{ field('lastName').name }}"
                [formCtrl]="formCtrl('lastName')" [required]="field('lastName').required" readonly="true" >
              </odsf-input>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row full-width">

          <!-- title -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label formCtrlId="{{ field('titleCode').id }}">
                <span name>{{ field('titleCode').label | translate | async }}</span>
              </odsf-label>
              <odsf-input formCtrlId="{{ field('titleCode').id }}" formCtrlName="{{ field('titleCode').name }}"
                [formCtrl]="formCtrl('titleCode')" [required]="field('titleCode').required" >
              </odsf-input>
            </div>
          </div>
          <!-- rank -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('rank').required" formCtrlId="{{ field('rank').id }}">
                <span name>{{ field("rank").label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
                
                field('rank').required &&
                formCtrl('rank')?.touched &&
                formCtrl('rank')?.hasError('required')
              " formCtrlId="{{ field('rank').id }}">
                {{
                field("rank").getValidator("required").message
                | translate
                : [field("rank").label | translate | async | lowercase]
                | async
                }}
              </odsf-error>

              <odsf-error *ngIf="
                
                field('rank').maxLength &&
                formCtrl('rank')?.hasError('maxlength')
              " formCtrlId="{{ field('rank').id }}">
                {{
                field("rank").getValidator("maxLength").message
                | translate
                : [
                field("rank").label | translate | async,
                formCtrl("rank")?.errors["maxlength"].requiredLength
                ]
                | async
                }}
              </odsf-error>

              <odsf-select formCtrlId="{{ field('rank').id }}" formCtrlName="{{ field('rank').name }}"
                [formCtrl]="formCtrl('rank')" [required]="field('rank').required" >
                <option value="" selected>Select</option>
                <option *ngFor="
                  let item of optionItems('rank') | orderBy : 'displayOrder';
                  let i = index;
                  trackBy: trackByFn
                " [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>

          <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
        </div>

        <div class="ontario-row full-width">
          <!-- court region code -->
          <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('courtRegionCode').required" formCtrlId="{{ field('courtRegionCode').id }}">
                <span name>{{
                  field("courtRegionCode").label | translate | async
                  }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error
                *ngIf=" field('courtRegionCode').required && formCtrl('courtRegionCode')?.touched && formCtrl('courtRegionCode')?.hasError('required')"
                formCtrlId="{{ field('courtRegionCode').id }}">
                {{ field('courtRegionCode').getValidator("required").message | translate :
                [field('courtRegionCode').label
                | translate | async | lowercase] | async }}
              </odsf-error>

              <odsf-select formCtrlId="{{ field('courtRegionCode').id }}"
                formCtrlName="{{ field('courtRegionCode').name }}" [formCtrl]="formCtrl('courtRegionCode')"
                [required]="field('courtRegionCode').required" >
                <option value="" selected>Select</option>
                <option *ngFor="
        let item of optionItems('region') | orderBy : 'displayOrder';
        let i = index;
        trackBy: trackByFn
      " [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
            </div>
          </div>

          <!-- badge number -->
          <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('badgeNumber').required" formCtrlId="{{ field('badgeNumber').id }}">
                <span name> {{ field("hasBadge").label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">

                <odsf-radio formCtrlId="{{ field('hasBadge').id }}" formCtrlName="{{ field('hasBadge').name }}"
                  [formCtrl]="formCtrl('hasBadge')" [required]="field('hasBadge').required"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]"
                  [values]=radioOptions [inOneLine]="true" [radioValue]="true">

                </odsf-radio>

              </div>

              <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">

                <odsf-input *ngIf="formCtrl('hasBadge')?.value === 'true'" formCtrlId="{{ field('badgeNumber').id }}"
                  formCtrlName="{{ field('badgeNumber').name }}" [formCtrl]="formCtrl('badgeNumber')"
                  [required]="field('badgeNumber').required" >
                </odsf-input>
            </div>
          </div>


        </div>
        </div>


        <div class="ontario-row full-width">


          <!-- role -->
          <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <div class="ontario-label">
                <span name>{{ field('serviceRole').label | translate | async }}</span>
              </div>

              <div *ngFor="let role of formCtrl('serviceRole').value">
                <span>{{ role.roleCode }}</span>
              </div>
              
              <!-- <odsf-input formCtrlId="{{ field('serviceRole').id }}" formCtrlName="{{ field('serviceRole').name }}"
                [formCtrl]="formCtrl('serviceRole')" [required]="field('serviceRole').required">
              </odsf-input> -->
            </div>
          </div>


          <!-- receive notification checkbox -->
          <!-- <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-error *ngIf="field('receiveNotification').required && formCtrl('receiveNotification')?.hasError('required')" formCtrlId="{{ field('receiveNotification').id }}">
                {{ field('receiveNotification').getValidator("required").message | translate : [field('receiveNotification').label | translate | async | lowercase] | async }}
              </odsf-error>
  
              <odsf-checkbox formCtrlId="{{ field('receiveNotification').id }}" formCtrlName="{{ field('receiveNotification').name }}"
                [formCtrl]="formCtrl('receiveNotification')" [required]="field('receiveNotification').required">
                {{ field("receiveNotification").label | translate | async }}
              </odsf-checkbox>
            </div>
          </div> -->
          <!-- badge number -->
          <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('receiveNotification').required" formCtrlId="{{ field('notificationEmail').id }}">
                <span name> {{ field("receiveNotification").label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">

                <odsf-radio formCtrlId="{{ field('receiveNotification').id }}" formCtrlName="{{ field('receiveNotification').name }}"
                  [formCtrl]="formCtrl('receiveNotification')" [required]="field('receiveNotification').required"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]"
                  [values]=radioOptions [inOneLine]="true" [radioValue]="true">

                </odsf-radio>

              </div>

              <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">

                <odsf-error *ngIf="
                formCtrl('notificationEmail')?.touched &&
                formCtrl('notificationEmail')?.hasError('required')
              " formCtrlId="{{ field('notificationEmail').id }}">
                          {{
                          field("notificationEmail").getValidator("requiredIf").message
                          | translate
                          : [
                          field("notificationEmail").label
                          | translate
                          | async
                          | lowercase
                          ]
                          | async
                          }}
                        </odsf-error>
          
                        <odsf-error *ngIf="
                field('notificationEmail').maxLength &&
                formCtrl('notificationEmail')?.hasError('maxlength')
              " formCtrlId="{{ field('notificationEmail').id }}">
                          {{
                          field("notificationEmail").getValidator("maxLength").message
                          | translate
                          : [
                          field("notificationEmail").label | translate | async,
                          formCtrl("notificationEmail")?.errors["maxlength"]
                          .requiredLength
                          ]
                          | async
                          }}
                        </odsf-error>
                <odsf-input formCtrlId="{{ field('notificationEmail').id }}"
                  formCtrlName="{{ field('notificationEmail').name }}" [formCtrl]="formCtrl('notificationEmail')"
                  [required]="field('notificationEmail').required" >
                </odsf-input>
            </div>
          </div>


        </div>
        </div>
      </form>
    </div>

    <!-- buttons -->
    <div class="ontario-row full-width">
      <button (click)="sendForm()" class="ontario-button ontario-button--primary">
        {{ "profile.update.btn" | translate | async }}
      </button>
      <!-- <button (click)="resetForm()" class="ontario-button ontario-button--secondary">{{ "profile.resetForm.btn" |
        translate | async }}</button> -->
      <button (click)="onCancel()" class="ontario-button ontario-button--secondary">{{
        "profile.cancel.btn" | translate | async }}</button>
    </div>

    <ng-template #errorNotificationTemplate>
      <div class="ontario-row full-width">
        <div
          class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
          <ods-page-alert role="error">
            <ng-container title>{{ "notification.error.title" | translate | async }}</ng-container>
            <ng-container body>
              <p>{{ "notification.error.content.invalidFormFields" | translate | async }}</p>
              <ul>
                <li *ngFor="let msg of notifications?.error?.data; let i = index; trackBy: trackByFn">
                  <a href="#">{{ msg }}</a>
                </li>
              </ul>
            </ng-container>
          </ods-page-alert>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
      </div>
    </ng-template>

    <ng-template #successNotificationTemplate>
      <div class="ontario-row full-width">
        <div
          class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
          <ods-page-alert role="success">
            <ng-container title>{{ notifications?.success?.title | translate | async }}</ng-container>
            <ng-container body>
              <p [innerHtml]="notifications?.success?.content | translate | async | safe : 'html'"></p>
            </ng-container>
          </ods-page-alert>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
      </div>
    </ng-template>
  </ng-container>
</div>