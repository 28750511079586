import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService, UserService } from '@app/core/auth';
import { APICacheService, AppCacheService } from '@app/core/cache';
import { POLICE_PORTAL_DOMAIN, environment } from '@environments/environment';
import { Language, LanguageService } from '@shared/language';
import { APP_REQUEST_CONTEXT } from '@shared/loading';
import { Preference } from '@shared/preference';
import { BehaviorSubject, EMPTY, Observable, forkJoin, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppService {
  private _pageReady: boolean = false;
  private subject = new BehaviorSubject(this._pageReady);
  private _pageReady$: Observable<boolean> = this.subject.asObservable();

  constructor(
    private apiCacheService: APICacheService,
    private appCache: AppCacheService,
    private languageService: LanguageService,
    private userApiService: UserApiService,
    private httpClient: HttpClient,
    private router: Router,
    private userService: UserService
  ) { }

  createAPICache(buildVersion: string): Observable<any> {
    return this.apiCacheService.create(buildVersion).pipe(tap(() => this.finalizeCache()));
  }

  loadI18n() {
    const en = environment.i18nDirectory + 'en.json';
    const fr = environment.i18nDirectory + 'fr.json';
    return forkJoin([
      this.httpClient.get(en, { context: APP_REQUEST_CONTEXT }),
      this.httpClient.get(fr, { context: APP_REQUEST_CONTEXT })
    ]).pipe(
      tap(() => this.finalizeCache()),
      tap((res: any) => ([this.appCache.en, this.appCache.fr] = res))
    );
  }

  setUserPrefLang(language) {
    this.appCache.setPreferenceLanguage(language);

    if (this.userService.loginUser) 
      this.userService.loginUser.langPreference = language;
    return this.userApiService
        .updateLocal(this.userService.loginUser  ,{ context: APP_REQUEST_CONTEXT })
        .pipe(tap(() => (this.languageService.language = language)));
  }

  serializeRouterUrl(path) {
    return this.router.serializeUrl(this.router.createUrlTree([path]));
  }

  goToCallbackPage(options?) {
    const path = '/';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToProfilePage(options?) {
    const path = `/profile/${options?.userId}` + (options?.editing ? `/edit` : ``);
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToHomePage(options?) {
    const path = '/home';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToMessagesPage(options?) {
    const path = '/messages';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToUsersPage(options?) {
    const path = '/users';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToErrorPage(options?) {
    const path = '/error';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToUnauthorizdPage(options?) {
    const path = '/unauthorized';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToLoginPage(options?) {
    const path = '/login';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToLogoutPage(options?) {
    const path = '/logout';
    return options?.blank ? window.open(this.serializeRouterUrl(path), '_blank') : this.router.navigate([path]);
  }

  goToPolicePortal(options?) {
    window.open(`${POLICE_PORTAL_DOMAIN}/login`, "_blank");
  }

  private finalizeCache() {
    this.appCache.initPreference();
    this.languageService.language = this.appCache.preference.language;
  }

  get pageReady() {
    return this._pageReady;
  }

  set pageReady(pageReady: boolean) {
    this._pageReady = pageReady;
    this.subject.next(this._pageReady);
  }

  get pageReady$() {
    return this._pageReady$;
  }

  
  private _enabledMenu: boolean = false;
  private _enabledMenuSubject = new BehaviorSubject(this._enabledMenu);
  private _enabledMenu$: Observable<boolean> = this._enabledMenuSubject.asObservable();

  set enabledMenu(enabledMenu: boolean) {
    this._enabledMenu = enabledMenu;
    this._enabledMenuSubject.next(this._enabledMenu);
  }
  
  get enabledMenu$() { 
    return this._enabledMenu$;
  }
  
  get enabledMenu() {
    return this._enabledMenu;
  }
  

}
