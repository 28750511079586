import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule} from '@angular/material/icon';
import { OdsBackToTopButtonComponent } from './ods-back-to-top-button/ods-back-to-top-button.component';
import { OdsCalloutComponent } from './ods-callout/ods-callout.component';
import { OdsPageAlertType } from './ods-page-alert/ods-page-alert-type.enum';
import { OdsPageAlertComponent } from './ods-page-alert/ods-page-alert.component';
import { OdsSpinnerComponent } from './ods-spinner/ods-spinner.component';
import { OdsStatusLabelComponent } from './ods-status-label/ods-status-label.component';
import { OdsTable } from './ods-table/ods-table.component';
import { OdsfCheckbox } from './odsf-checkbox/odsf-checkbox.component';
import { OdsfRadio } from './odsf-radio/odsf-radio.component';
import { OdsfError } from './odsf-error/odsf-error.component';
import { OdsfInput } from './odsf-input/odsf-input.component';
import { OdsfLabel } from './odsf-label/odsf-label.component';
import { OdsfSelect } from './odsf-select/odsf-select.component';
import { OdsfTextarea } from './odsf-textarea/odsf-textarea.component';
import { OdsDialog, OdsDialogData } from './ods-dialog/ods-dialog.component';
import { OdsfCardsComponent } from './odsf-cards/odsf-cards.component';
import { I18nModule } from '@/src/app/core/i18n';

const materialModules = [
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSortModule,
  MatPaginatorModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatIconModule
];

const odsComponents = [
  OdsCalloutComponent,
  OdsPageAlertComponent,
  OdsSpinnerComponent,
  OdsBackToTopButtonComponent,
  OdsStatusLabelComponent,
  OdsTable,
  OdsDialog,
  OdsfCardsComponent
];

const odsFormComponents = [OdsfLabel, OdsfInput, OdsfTextarea, OdsfSelect, OdsfCheckbox, OdsfRadio, OdsfError];

@NgModule({
  declarations: [...odsComponents, ...odsFormComponents],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, I18nModule, ...materialModules],
  exports: [...odsComponents, ...odsFormComponents]
})
class OdsComponentsModule {}

export {
  OdsBackToTopButtonComponent,
  OdsCalloutComponent,
  OdsComponentsModule,
  OdsPageAlertComponent,
  OdsPageAlertType,
  OdsSpinnerComponent,
  OdsStatusLabelComponent,
  OdsTable,
  OdsfCheckbox,
  OdsfRadio,
  OdsfError,
  OdsfInput,
  OdsfLabel,
  OdsfSelect,
  OdsfTextarea,
  OdsDialog,
  OdsDialogData,
  OdsfCardsComponent
};
