import { NgModule } from '@angular/core';
import { SharedComponentsModule } from './components';
import { ContentTransformationModule } from './content-transformation';
import { DomListenersModule } from './dom-listeners';
import { FormInputsModule } from './form-inputs';

const mods = [FormInputsModule, ContentTransformationModule, DomListenersModule, SharedComponentsModule];

@NgModule({
  imports: [...mods],
  exports: [...mods]
})
class SharedModule {}

export { SharedModule };
