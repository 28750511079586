<div class="ontario-error-messaging" id="{{ formCtrlId + '-field-error' }}" role="alert">
  <span class="ontario-error-messaging__icon">
    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
      <use href="#ontario-icon-alert-error"></use>
    </svg>
  </span>
  <span class="ontario-error-messaging__content">
    <ng-content></ng-content>
  </span>
</div>
