import { NgModule } from '@angular/core';
import { AbsoluteValuePipe } from './absolute-value.pipe';
import { OrderPipe } from './order.pipe';
import { ToPascalCasePipe } from './pascal-case.pipe';
import { SafePipe } from './safe.pipe';

const mods = [AbsoluteValuePipe, SafePipe, ToPascalCasePipe, OrderPipe];

@NgModule({
  declarations: [...mods],
  exports: [...mods]
})
class ContentTransformationModule { }

export { AbsoluteValuePipe, ContentTransformationModule, ToPascalCasePipe, SafePipe };

