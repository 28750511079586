<ng-content select="[spinner]" *ngIf="loading"></ng-content>

<mat-form-field *ngIf="showFilter">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" />
</mat-form-field>

<div class="table-container" style="overflow: auto">
  
  <table mat-table #table [dataSource]="dataSource" class="example-table" matSort matSortActive="sentDate" matSortDisableClear matSortDirection="desc">
    <caption *ngIf="tableCaption">{{tableCaption}}</caption>
    <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index;">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ (columnsTitle && columnsTitle[i])? columnsTitle[i] : column}} </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'vertical-align': column === 'actions'?'middle':''}">
        <ng-template [ngIf]="includeButtonsOrNot(element[column])" [ngIfElse]="elseTemplate">
          <mat-icon *ngIf="element[column]?.toLowerCase()?.includes('[green_check_mark]')" style="color: rgb(0, 128, 55);">check</mat-icon>
          <mat-icon *ngIf="element[column]?.toLowerCase()?.includes('[gray_check_mark]')" style="color: rgb(128, 128, 128);">check</mat-icon>

          <mat-icon *ngIf="element[column]?.toLowerCase()?.includes('[add]')" (click)="performActionAdd(element)">add</mat-icon>
          <mat-icon *ngIf="element[column]?.toLowerCase()?.includes('[edit]')" (click)="performActionEdit(element)">edit</mat-icon>
          <mat-icon *ngIf="element[column]?.toLowerCase()?.includes('[delete]')" (click)="performActionDelete(element)">delete</mat-icon>
          <button mat-button *ngIf="element[column]?.toLowerCase()?.includes('[download]')" (click)="performActionDownload(element)">Download</button>
          <input *ngIf="element[column]?.toLowerCase()?.includes('[upload]')" type="file" accept="application/pdf" (change)="performActionUpload(element)">
        </ng-template>
        <ng-template #elseTemplate>
          <div [innerHTML]="element[column]"></div> 
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; let headerData" [ngStyle]="{'background-color': headerData === 'newColumn' ? 'lightblue' : ''}">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let rowData" [ngStyle]="{'background-color': rowData.id % 2 === 0 ? 'lightgray' : ''}">
    </tr>
  </table>
</div>


<mat-paginator *ngIf="showPaginator" [pageSizeOptions]="[5, 10]" [pageSize]="5" showFirstLastButtons></mat-paginator>
