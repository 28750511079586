import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'odsf-label',
  templateUrl: './odsf-label.component.html'
})
export class OdsfLabel implements OnInit {
  @Input() required: boolean;
  @Input() formCtrlId: string;

  constructor() {}

  ngOnInit(): void {}
}
