import { HttpHeaders, HttpContext, HttpParams } from '@angular/common/http';
import { ApiInterceptor } from './api.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { HttpConstants } from './http.constant';
import { AppSchema, ServerRequest } from './server-request.model';
import { ServerResponse } from './server-response.model';
import { CorsInterceptor } from './cors.interceptor';
import { AuthInterceptor } from './auth.interceptor';

interface NgHttpOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  context?: HttpContext;
  observe?: "body";
  params?: HttpParams | {};
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;
}

export { ApiInterceptor, AppSchema, ErrorInterceptor, HttpConstants, NgHttpOptions, ServerRequest, ServerResponse, CorsInterceptor, AuthInterceptor };
