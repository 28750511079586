import { userForm } from '@app/core/auth';
import { NotificationType } from '@app/core/notifications';
import { PageView, getViewFromUrl } from '@app/view';
import { environment } from '@environments/environment';
import { Observable, combineLatest, concat, distinctUntilChanged, map, of, pairwise, startWith, switchMap, tap } from 'rxjs';

/* =========================== BASE SUBSCRIPTIONS =========================== */

export const onPageViewChanged$ =
  next =>
    ({ route, router, dispatcher }) => {
      return combineLatest([of(router.url), route.params]).pipe(
        distinctUntilChanged(),
        switchMap(([url, params]: any) => next({ dispatcher, url, params, viewType: getViewFromUrl(url) }))
      );
    };

export const onNotificationsChanged$ = notificationCriteria => notificationType => (notifications$: Observable<any[]>, next) => {
  return notifications$.pipe(
    map(ns => ns?.filter(n => notificationCriteria(n))),
    map(ns => ns?.filter(n => n?.type === notificationType)),
    tap(ns => next(ns))
  );
};

export const onFormInputChanged$ = fieldName => (cond, trueResult, falseResult, complete?) => form => {
  // track prev and curr value using startWith and pairwise
  return concat(form.get(fieldName).valueChanges.pipe(startWith(null), pairwise())).pipe(
    distinctUntilChanged(),
    tap(([prev, curr]: any) => (cond({ form, prev, curr }) ? trueResult({ form, prev, curr }) : falseResult({ form, prev, curr }))),
    tap(() => complete ? complete({ form }) : null)
  );
};

/* =========================== BASE SUBSCRIPTIONS =========================== */

/* =========================== User SUBSCRIPTIONS =========================== */

export const onUserFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  if (viewType === PageView.Create) {
    return of(userForm({ validateForm }));
  }

  const isReadOnlyView = viewType === PageView.Read;
  return dispatcher.load(true).pipe(switchMap(() => dispatcher.findById(params.id, true).pipe(map((s: any) => {
    let userData = { ...s, courtRegionCode: s.region, hasBadge: s.badgeNo ? "true" : "false", badgeNumber: s.badgeNo, serviceRole: s.services }
    return userForm({ data: userData, validateForm, disableForm: isReadOnlyView })
  }
  ))));
});

export const onProfileFormViewChanged$ = onPageViewChanged$(({ dispatcher, params, viewType }) => {
  const validateForm = environment.validateForm;
  // if (viewType === PageView.Create) {
  //   return of(userForm({ data: { declare: true }, validateForm }));
  // }

  // const isReadOnlyView = viewType === PageView.Read;

  return dispatcher.load(true).pipe(switchMap(() => dispatcher.findById(dispatcher.userId, true).pipe(map((s: any) => {
    let userData = { ...s, courtRegionCode: s.region, hasBadge: s.badgeNo ? "true" : "false", badgeNumber: s.badgeNo, serviceRole: s.services }

    const user = userForm({ data: userData, validateForm, disableForm: true });
    user.form.get('rank').enable()
    user.form.get('courtRegionCode').enable()
    user.form.get('receiveNotification').enable()
    return user;
  }
  ))));
});

export const onUserFormNotificationsChanged$ = onNotificationsChanged$(n => n?.id?.includes('User'));

export const onUserFormErrorsChanged$ = onUserFormNotificationsChanged$(NotificationType.Error);
export const onUserFormSuccessChanged$ = onUserFormNotificationsChanged$(NotificationType.Success);

export const onHasBadgeChanged$ = onFormInputChanged$('hasBadge');
export const onRecieveNotificationChanged$ = onFormInputChanged$('receiveNotification');
export const onIsSecondedChanged$ = onFormInputChanged$('isSeconded');
export const onIsActiveChanged$ = onFormInputChanged$('isActive');
export const onReadOnlyChanged$ = onFormInputChanged$('readOnly');

export const onUserFormHasBadgeChanged$ = onHasBadgeChanged$(
  ({ prev, curr }) => (curr !== prev && curr === "true"),
  ({ form }) => form.get('badgeNumber').enable(),
  ({ form }) => form.get('badgeNumber').disable(),
  ({ form }) => form.get('badgeNumber').reset()
);

export const onUserFormRecieveNotificationChanged$ = onRecieveNotificationChanged$(
  ({ prev, curr }) => (curr !== prev && (curr === "true" || curr === true)),
  ({ form }) => {form.get('notificationEmail').enable(); form.get('notificationEmail').setValue(form.get('loginEmail').value)},
  ({ form }) => {form.get('notificationEmail').disable(), form.get('notificationEmail').reset()}
);

export const onUserFormIsSecondedChanged$ = onIsSecondedChanged$(
  ({ prev, curr }) => (curr !== prev && curr === true),
  ({ form }) => { form.get('secondedAgencyCode').enable(); form.get('secondedEmail').enable(); },
  ({ form }) => { form.get('secondedAgencyCode').disable(); form.get('secondedEmail').disable(); },
  ({ form }) => { form.get('secondedAgencyCode').reset(); form.get('secondedEmail').reset(); }
);

export const onUserFormIsActiveChanged$ = onIsActiveChanged$(
  ({ prev, curr }) => (curr !== prev && curr === false),
  ({ form }) => form.get('deactivationReason').enable(),
  ({ form }) => form.get('deactivationReason').disable(),
  ({ form }) => form.get('deactivationReason').reset()
);

export const onUserFormReadOnlyChanged$ = onReadOnlyChanged$(
  ({ prev, curr }) => (curr !== prev && curr === true),
  ({ form }) => form.get('readOnlyReason').enable(),
  ({ form }) => form.get('readOnlyReason').disable(),
  ({ form }) => form.get('readOnlyReason').reset()
);