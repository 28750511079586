import { NgModule } from '@angular/core';
import { OdsComponentsModule } from './ods';

const components = [OdsComponentsModule];

@NgModule({
  imports: [...components],
  exports: [...components]
})
class SharedComponentsModule {}

export { SharedComponentsModule };
