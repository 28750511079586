import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ods-status-label',
  templateUrl: './ods-status-label.component.html'
})
export class OdsStatusLabelComponent implements OnInit {
  @Input() color: string;
  @Input() classes: string;

  constructor() {}

  ngOnInit(): void {}
}
