import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface OdsDialogData {
  title: string,
  content: string,
  ok?: string,
  yes?: string,
  no?: string
}

@Component({
  selector: 'ods-dialog',
  templateUrl: './ods-dialog.component.html',
  styleUrls: ['./ods-dialog.component.scss'],
})
export class OdsDialog {
  constructor(
    public dialogRef: MatDialogRef<OdsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: OdsDialogData
  ) { }
}