import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applicationAndRolesPipe'
})
export class ApplicationAndRolesPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) return '';

    const serviceCodes = {
      'CJDD': 'Justice Portal',
      'ESW': 'eSW Enforcement Agency Module' // Add more service codes here if needed
    };

    const roleCodes = {
      'Super Admin': 'Super administrator',
      'Admin': 'Administrator',
      'Basic': 'Basic user',
      'Full Access': 'Officer',
      'Business': 'Business User',
      'Read Only': 'Officer (Read Access)'
    };

    const service = serviceCodes[value.serviceCode] || '';
    const role = roleCodes[value.roleCode];
    let status = '';
    //TODO: when flow is confirmed
    // if (!value.isActive && value.isReadOnly) {
    //   status = "(Read Access, Deactivated)";
    // } else if (!value.isActive && !value.isReadOnly) {
    //   status = "(Deactivated)";
    // } else if (value.isActive && value.isReadOnly) {
    //   status = "(Read Access)";
    // }
    return `${service} - ${role} ${status}`;
  }
}