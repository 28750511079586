export class AppConstants {
  public static readonly NAV_ACTION = {
    CLOSE: 'close',
    OPEN: 'open'
  };
}

export interface ServerReferenceData {
  id: number;
  tableName: string;
  code: string;
  value: string;
  valueFr: string;
}

export interface I18nString {
  en: string;
  fr: string;
}

export interface ReferenceData {
  id: string;
  name: I18nString;
  displayOrder: number;
}
