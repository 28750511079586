<div class="container-full-width">
  <!-- notifications -->
  <div class="ontario-row full-width">
    <!-- success -->
    <div *ngIf="notifications?.success"
      class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
      <ods-page-alert role="success">
        <ng-container title>{{ notifications?.success?.title | translate | async }}</ng-container>
        <ng-container body>
          <p [innerHtml]="notifications?.success?.content | translate | async | safe : 'html'"></p>
        </ng-container>
      </ods-page-alert>
    </div>

    <!-- error -->
    <div *ngIf="notifications?.error"
      class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
      <ods-page-alert role="error">
        <ng-container title>{{ notifications?.error?.title | translate | async }}</ng-container>
        <ng-container body>
          <p>{{ notifications?.error?.content | translate | async }}</p>
        </ng-container>
      </ods-page-alert>
    </div>

    <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
  </div>

  <!-- search bar -->
  <div class="ontario-row full-width">
    <div id="describedBy" hidden>{{ "users.pageDescription.text" | translate | async }}</div>
    <form novalidate [formGroup]="searchForm" class="ontario-row full-width">
      <div class="ontario-row full-width">
        <!-- <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!"> -->
        <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label formCtrlId="search-first-name">
              <span name>{{ "userForm.field.firstName.name" | translate | async }}</span>
            </odsf-label>

            <odsf-input #searchFirstName formCtrlId="search-first-name" formCtrlName="searchFirstName"
              [formCtrl]="formCtrl('searchFirstName')" [disabled]="loadingResults" >
            </odsf-input>
          </div>
        </div>
        <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label formCtrlId="search-last-name">
              <span name>{{ "userForm.field.lastName.name" | translate | async }}</span>
            </odsf-label>

            <odsf-input #searchLastName formCtrlId="search-last-name" formCtrlName="searchLastName"
              [formCtrl]="formCtrl('searchLastName')" [disabled]="loadingResults" >
            </odsf-input>
          </div>
        </div>
        <div class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label formCtrlId="search-email">
              <span name>{{ "users.table.column.email.title" | translate | async }}</span>
            </odsf-label>

            <odsf-input #searchEmail formCtrlId="search-email" formCtrlName="searchEmail"
              [formCtrl]="formCtrl('searchEmail')" [disabled]="loadingResults" >
            </odsf-input>
          </div>
        </div>
        <div
          class="ontario-columns ontario-large-3 ontario-medium-6 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
          <div class="ontario-form-group">
            <odsf-label formCtrlId="search-employee-number">
              <span name>{{ "users.field.badge.number.label.text" | translate | async }}</span>
            </odsf-label>

            <odsf-input #searchEmployeeNumber formCtrlId="search-employee-number" formCtrlName="searchEmployeeNumber"
              [formCtrl]="formCtrl('searchEmployeeNumber')" [disabled]="loadingResults" >
            </odsf-input>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </form>
  </div>


  <div class="ontario-row  full-width button-container ">
    <!-- Search button -->
    <button (click)="filterUsers()" class="ontario-button ontario-button--primary">
      {{ "users.button.search.text" | translate | async }}
    </button>
    <!-- create button -->
    <button [routerLink]="['/user']" class="ontario-button ontario-button--secondary margin-right-0">
      {{ "users.button.create.text" | translate | async }}
    </button>
    <!-- Reports button -->
    <!-- <button [routerLink]="['/user']" class="ontario-button ontario-button--secondary margin-right-0 ">
      {{ "users.button.reports.text" | translate | async }}
    </button> -->

    <!-- reset button -->
    <button (click)="reset()" class="ontario-button ontario-button--tertiary margin-right-0">
      {{ "users.button.reset.text" | translate | async }}
    </button>
  </div>

  <!-- <div class="ontario-row">
  <ods-spinner *ngIf="loadingResults" [fullScreen]="false">
    {{ "app.spinner.text" | translate | async }}
  </ods-spinner>
  <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
    <div class="ontario-form-group">
      <ods-table #usersListTable [columns]="displayedColumns"  [dataSourceByArray]="dataSource"
        [tableCaption]="'users.caption.users' | translate | async"
        [columnsTitle]="['users.table.column.name.title'| translate | async, 
        'users.table.column.email.title'| translate | async, 
        'users.table.column.role.title'| translate | async, 
        'users.table.column.edit.title'| translate | async]"
      ></ods-table>
    </div>
  </div> -->


  <!-- TODO://change the table to ods-table  -->
  <div class="ontario-row full-width">
    <div class="example-container" style="position: relative; width: 100%" [class.loading]="loadingResults">

      <ods-spinner *ngIf="loadingResults" [fullScreen]="false">
        {{ "app.spinner.text" | translate | async }}
      </ods-spinner>

      <div class="example-table-container" style="overflow: auto">
        <table id="usersList" mat-table [dataSource]="dataSource?.data.length > 0 ? dataSource : emptyData"
          class="example-table" matSort #sort="matSort" matSortActive="name" matSortDisableClear
          matSortDirection="desc">
          <caption>{{"users.caption.users" | translate | async }}</caption>
          <!-- name column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "users.table.column.name.title" | translate | async
              }}</th>
            <!-- <td mat-cell *matCellDef="let row">{{ row?.name | namepipe }}</td> -->
            <td mat-cell *matCellDef="let row">{{ row?.firstName }}, {{ row?.lastName }} {{row.badgeNo ? '(' +row.badgeNo+ ')':''}}</td>
          </ng-container>

          <!-- email column -->
          <ng-container matColumnDef="loginEmail">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "users.table.column.email.title" | translate |
              async }}</th>
            <td mat-cell *matCellDef="let row">{{ row?.loginEmail }}</td>
          </ng-container>

          <!-- role column -->
          <ng-container matColumnDef="applicationsAndRoles">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="no-max-width">{{
              "users.table.column.role.title" | translate | async
              }}</th>
            <!-- <td mat-cell *matCellDef="let row">{{ row?.services | rolepipe }}</td> -->
            <td mat-cell *matCellDef="let row" class="no-max-width">
              <div *ngFor="let role of row?.services"><span>{{ role |applicationAndRolesPipe}}</span></div>
            </td>
          </ng-container>

          <!-- Status column -->
          <ng-container matColumnDef="isActive">
            <th class="small-column" mat-header-cell *matHeaderCellDef mat-sort-header >{{
              "users.table.column.isActive.title" | translate | async
              }}</th>
            <td mat-cell *matCellDef="let row" class="small-column">{{ row?.isActive ? 'Yes': 'No'}}</td>
          </ng-container>

          <!-- edit column -->
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>{{ "users.table.column.edit.title" | translate | async }}</th>
            <td mat-cell *matCellDef="let row">
              <a *ngIf="canEdit(row)" class="ontario-__link mat-column-edit-btn" [routerLink]="['/user/',row.id,'edit']"
                aria-label="Edit User">
                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                  viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                  <use href="#ontario-icon-edit"></use>
                </svg>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="empty-row">
            <mat-cell *matCellDef="let element">{{ "table.noData.text" | translate | async }}</mat-cell>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: dataSource?.data?.length > 0 ? displayedColumns : ['empty-row'];"
            [class.ontario-table-row--highlight]="row?.highlighted"></tr>
        </table>
      </div>

      <mat-paginator #paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="5"
        showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>