<!-- <div id="content-container">

<div class="ontario-row">
  <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">
    <div class="ontario-form-group">
      <ods-table #usersListTable [columns]="usersListColumns"  [dataSourceByArray]="usersList"
        [tableCaption]="'officer.users.table.caption' | translate | async"
        [columnsTitle]="['officer.users.table.column.title.1'| translate | async, 
        'officer.users.table.column.title.2'| translate | async, 
        'officer.users.table.column.title.3'| translate | async, 
        'officer.users.table.column.title.4'| translate | async, 
        'officer.users.table.column.title.5'| translate | async, 
        'officer.users.table.column.title.6'| translate | async ]"
      ></ods-table>
    </div>
  </div>

  <div class="ontario-row">
    <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">

      <p class="title">{{ 'officer.enrolledService.title.text' | translate | async }}</p>
    </div>
  </div>

  <div class="ontario-row">
    <div class="ontario-columns ontario-large-4 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
      <div class="service-container">
        <div *ngIf="hasAccess('ESW')" class="service" (click)="goToPolicePortal()">
          <div class="service-icon"></div>
          <p class="service-title">{{ 'officer.enrolledService.esw.button.text' | translate | async }}</p>
        </div>
      </div>
    </div>

    <div class="ontario-columns ontario-large-4 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">
      <div class="service-container">
        <div *ngIf="hasAccess('ESW')" class="service">
          <div class="service-icon"></div>
          <p class="service-title">{{ 'home.coming.soon.title' | translate | async }}</p>
        </div>
      </div>
    </div>

    <div class="ontario-columns ontario-large-4  ontario-medium-0 ontario-small-0 ontario-padding-left-0-!"></div>
  </div>
  <div class="ontario-row">
    <odsf-cards></odsf-cards>
  </div>
  
  <div class="ontario-row">
    <div class="ontario-columns ontario-large-10 ontario-medium-11 ontario-small-12 ontario-padding-left-0-!">

      <p class="title">{{ 'officer.userManagement.title.text' | translate | async }}</p>
       
      <div class="service-container">
        <div *ngIf="hasAccess('CJDD')" class="service" (click)="goToUserManagement()">
          <div class="service-icon"></div>
          <p class="service-title">{{ 'officer.userManagement.title.text' | translate | async }}</p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container-full-width">
  <h1>{{ "officer.enrolledService.title.text" | translate | async }}</h1>
  <div class="ontario-row full-width">
    <!-- <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12"> -->
      <odsf-cards [dataList]="applicationsList"></odsf-cards>
    <!-- </div> -->
  </div>
    
</div>