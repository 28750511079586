<div id="content-container">
  <div id="image-section">
    <!-- <div id="landing-description">
      <p id="landing-description-title">{{ 'home.title' | translate | async }}</p>
      <p id="landing-description-subtitle">{{ 'home.subtitle.text' | translate | async }}</p>
    </div> -->

  </div>
  <div class="login-card">
    <div class="ontario-card__container ">
      <div class="ontario-card ontario-card--default  ontario-card--position-vertical  ">
        <div class="ontario-card__text-container ">
          <h2 class="ontario-card__heading">
            {{ 'home.title' | translate | async }}
          </h2>          
          <div class="ontario-card__description">
            <p>{{ 'home.subtitle.text' | translate | async }} </p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ng-container *ngIf="!loggedIn">
        <button class="ontario-button ontario-button--primary" (click)="login()">{{ 'menu.login.text' | translate |
          async }}</button>
      </ng-container>
      <ng-container *ngIf="loggedIn">
        <button class="ontario-button ontario-button--primary" (click)="logout()">{{ 'menu.logout.text' | translate |
          async }}</button>
      </ng-container>
    </div>
  </div>

  <!-- <div id="functional-content-section"> -->
  <!-- <div id="service-section">
      <div id="available">
        <p id="services-title">{{ 'officer.enrolledService.service.title.text' | translate | async }}</p>
        
        <div class="services-list">
          {{ 'officer.enrolledService.esw.button.text' | translate | async }}
          <mat-icon class="info-icon" (click)="taggleHintOfeSW('showHintOfeSW')" >info</mat-icon>
        </div>
        <div *ngIf="showHintOfeSW" class="services-list-hint">
          {{'officer.enrolledService.esw.button.text.tooltips' | translate | async}}
        </div> 

        <div class="coming-soon">
          <p class="coming-soon-title">{{ 'home.coming.soon.title' | translate | async }}</p>
          <p class="coming-soon-text"> {{ 'home.coming.soon.text' | translate | async }}</p>
        
          <div class="services-list">
            {{ 'home.coming.soon.other.future.text' | translate | async }}
            <mat-icon class="info-icon" (click)="taggleHintOfeSW('showHintOfOther')" >info</mat-icon>
          </div>
          <div *ngIf="showHintOfOther" class="services-list-hint" innerHtml="{{'home.coming.soon.other.future.detail.html' | translate | async}}" >
          </div>       
        </div>
      </div>
    </div> -->
  <!-- <div id="login-section">
      <ng-container *ngIf="!loggedIn">
        <p id="login-title">{{ 'officer.login.button.text' | translate | async }}</p>
        <button class="button-green-primary" (click)="login()">{{ 'menu.login.text' | translate | async | uppercase }}</button>
      </ng-container>
      <ng-container *ngIf="loggedIn">
        <p id="login-title">{{ 'officer.logout.button.text' | translate | async }}</p>
        <button class="button-green-primary" (click)="logout()">{{ 'menu.logout.text' | translate | async | uppercase }}</button>
      </ng-container>
      <hr/>
      <div>
        <p class="users-management-title">{{ 'home.userManagement.title.text' | translate | async }}</p>
        <button class="button-green-primary" [disabled]="!(loggedIn && hasAccess('CJDD'))" (click)="goToUserManagement()">{{ 'home.userManagement.cjdd.button.text' | translate | async | uppercase }}</button>
        <div class="users-management-text" innerHTML="{{'home.users.management.features.detail.html' | translate | async}}"></div>
      </div>
    </div> -->
  <!-- </div> -->
</div>