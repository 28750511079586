import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseViewComponent } from '@app/view';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from '@app/core/auth';
import { AppService } from '@app/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent extends BaseViewComponent implements OnInit, AfterViewInit {
  loggedIn = null;
  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private authService: OidcSecurityService,
    private userService: UserService,
    private appService: AppService
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.userService.loggedIn$.subscribe(loggedIn => {
      this.loggedIn = loggedIn
      this.changeDetectorRef.detectChanges();
    })
  }

  ngAfterViewInit(): void {
    this.appService.enabledMenu = false;
  }

  get authorizedServices() {
    return this.userService.authorizedServices;
  }

  get isAuthorized() {
    return this.userService.isAuthorized;
  }
  goToPolicePortal() {
    this.appService.goToPolicePortal({ blank: true });
  }

  goToUserManagement() {
    this.appService.goToUsersPage({ blank: true });
  }

  hasAccess(serviceName: string) {
    return this.userService.hasAccess(serviceName);
  }

  login() {
    localStorage.setItem('postLogoutRedirectUri', '/officer');
    this.router.navigate(['/login']);
  }

  logout() {
    this.userService.logOffAndRevokeAccess()
      .subscribe();
  }

  showHintOfeSW: boolean = true;
  showHintOfUM: boolean = true;
  showHintOfOther: boolean = false;

  taggleHintOfeSW(value): void {
    this[value] = !this[value];
  }

}
