import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

enum FormControlAction {
  Enable = <any>'enable',
  Disable = <any>'disable'
}

@Directive({
  selector: '[disableControl]'
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    const actionType = condition ? FormControlAction.Disable : FormControlAction.Enable;
    if (this.ngControl?.control && this.ngControl?.control[actionType]) {
      this.ngControl?.control[actionType]({ onlySelf: true, emitEvent: false });
    }
  }

  constructor(private ngControl: NgControl) {}
}
