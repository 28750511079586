import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'odsf-error',
  templateUrl: './odsf-error.component.html'
})
export class OdsfError implements OnInit {
  @Input() formCtrlId: string;

  constructor() {}

  ngOnInit(): void {}
}
