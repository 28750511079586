import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@app/core';
import { AppCacheService } from '@app/core/cache';
import { LanguageService } from '@shared/language';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Pipe({
  name: 'translate',
  pure: true
})
export class TranslatePipe implements PipeTransform {
  constructor(private appCache: AppCacheService, private languageService: LanguageService) {}

  transform(key: string, tokens?: any[]): Observable<string> {
    return this.languageService.language$.pipe(
      concatMap((lang: any = 'en', index: number) => {
        const json = this.appCache[lang];
        const text = json?.[key];
        if (text) {
          if (tokens && tokens.length) {
            return of(Utils.format(text, tokens));
          } else
            return of(text);
        } else
          return of(key);
      })
    );
  }
}
