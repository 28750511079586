import { APP_REQUEST_CONTEXT } from "@/src/shared/loading";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgHttpOptions } from "@app/core/http";
import { API_ENDPOINTS } from "@environments/environment";
import { Observable, map, of, tap } from "rxjs";
import { UserModel } from ".";

const parseBool = (v) => (v === "Y" ? true : false);
const boolToStr = (v) => (!!v ? "Y" : "N");
let test = [];

const transformer = {
  rawToModel: (u) => {
    return {
      id: u?.id ?? null,
      loginEmail: u?.loginEmail, //required
      firstName: u?.firstName, //required
      lastName: u?.lastName, //required
      agency: u?.agency, //required
      isActive: u?.isActive, //required
      hasBadge: u?.badgeNo != null,
      badgeNumber: u?.badgeNo,
      notificationEmail: u?.notificationEmail ?? null,
      phone: u?.phone ?? null,
      titleCode: u?.titleCode, //required
      rank: u?.rank, //required
      courtRegionCode: u?.region, //required
      receiveNotification: parseBool(u?.receiveNotification) ?? false,
      isSeconded: parseBool(u?.isSeconded) ?? false,
      isDataAccess: parseBool(u?.isDataAccess) ?? false,
      readOnly: parseBool(u?.isReadOnly) ?? false,
      certEmail: u?.certEmail ?? null,
      serviceRole: u?.services ?? null
    };
  },
  modelToRaw: (u) => {
    return {
      id: u?.id ?? null,
      loginEmail: u?.loginEmail, //required
      firstName: u?.firstName, //required
      lastName: u?.lastName, //required
      agency: u?.agency, //required
      isActive: u?.isActive, //required
      notificationEmail: ((u?.receiveNotification === 'true' || u?.receiveNotification === true) && u?.notificationEmail) ? u?.notificationEmail : null,
      phone: u?.phone ?? null,
      badgeNo: u?.badgeNumber ?? null,
      titleCode: u?.titleCode, //required
      rank: u?.rank, //required
      region: u?.courtRegionCode, //required
      receiveNotification: u?.receiveNotification ?? false,
      isSeconded: u?.isSeconded ?? false,
      isDataAccess: u?.isDataAccess ?? false,
      isReadOnly: u?.readOnly ?? false,
      certEmail: u?.certEmail ?? null,
      services: u?.serviceRole ?? null
    };
  },
};

@Injectable({ providedIn: "root" })
export class UserApiService {
  constructor(private httpClient: HttpClient) {}

  getLocal(username: string, options?: NgHttpOptions): Observable<UserModel> {
    // return this.httpClient.get(`${API_ENDPOINTS.USERS}/username/${username}`, options) as Observable<UserModel>;
    const user = {
      id: 1,
      name: "John, Smith",
      username: "frontend",
      email: "john.smith@ontario.ca",
      badge: "1234567",
      agency: "ABC Agency",
      langPreference: "en",
    } as UserModel;

    return of(user);
  }

  updateLocal(user: UserModel, options?: NgHttpOptions): Observable<UserModel> {
    // return this.httpClient.put(`${API_ENDPOINTS.USERS}/id/${user.id}`, user, options) as Observable<UserModel>;
    return of(user);
  }

  get(id: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint =
      API_ENDPOINTS.USER_GET.indexOf(`{userId}`) === -1
        ? `${API_ENDPOINTS.USER_GET}/${id}`
        : API_ENDPOINTS.USER_GET.replace(`{userId}`, id);
    return this.httpClient.get(endpoint, options) as Observable<any>;
  }

  getByEmail(email?: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.USER_GET_BY_EMAIL.replace(`{email}`, email);
    return (this.httpClient.get(endpoint, options) as Observable<any>).pipe(
      map((x) => {
        console.log("=============raw data", x);
        return transformer.rawToModel(x); //{...x, courtRegionCode: x.region}
      })
    );
  }

  getAllAccountByCertEmail(email?: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.USER_GET_BY_CERT_EMAIL.replace(
      `{email}`,
      email
    );
    return this.httpClient.get(endpoint, options) as unknown as Observable<
      any[]
    >;
  }

  getAll(isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    return this.httpClient.get(
      API_ENDPOINTS.USER,
      options
    ) as unknown as Observable<any[]>;
  }

  getAllByAgency(agency?: string, isAppRequest?: boolean) {
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.USER_GET_BY_AGENCY.replace(`{agency}`, agency);
    return this.httpClient.get(
      endpoint,
      options
    ) as unknown as Observable<any[]>;
  }

  getUserHistory(id: string) {
    const isAppRequest = true;
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    const endpoint = API_ENDPOINTS.USER_HISTORY.replace(`{id}`, id);
    return this.httpClient.get(endpoint, options) as unknown as Observable<
      any[]
    >;
  }

  create(user, isAppRequest?: boolean) {
    console.log("=====create===========");
    console.log("user:", user);
    const userPayload = transformer.modelToRaw(user);
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    console.log("userload:", userPayload);
    return this.httpClient.post(
      API_ENDPOINTS.USER_CREATE,
      userPayload,
      options
    ) as Observable<any>;
  }

  update(user, isAppRequest?: boolean) {
    console.log("=====update===========");
    console.log("user:", user);
    const userPayload = transformer.modelToRaw(user);
    const options: any = {};
    if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
    console.log("userload:", userPayload);
    return this.httpClient.put(
      API_ENDPOINTS.USER_CREATE,
      userPayload,
      options
    ) as Observable<any>;
  }
}
