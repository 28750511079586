<div class="container-full-width">
  <ng-container *ngIf="view.isReady">
    <ng-container *ngIf="isFormView">
      <!-- title -->
      <div class="ontario-row full-width">
        <h1>{{ "userForm.create.title" | translate | async }}</h1>
      </div>

      <!-- back to users button -->
      <!-- <ng-container
      [ngTemplateOutlet]="formTemplateUsersNavigater"
    ></ng-container> -->

      <!-- form input errors from server -->
      <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

      <div *ngIf="user?.form" class="ontario-row full-width">
        <ng-container *ngTemplateOutlet="formTemplate; context: { readonly: false }"></ng-container>
      </div>

      <!-- buttons -->
      <div class="ontario-row full-width">
        <button (click)="sendForm()" class="ontario-button ontario-button--primary">
          {{ "userForm.submit.btn" | translate | async }}
        </button>

        <button [routerLink]="['/users']" class="ontario-button ontario-button--secondary">
          {{ "userForm.cancel.btn" | translate | async }}
        </button>
        <!-- <ontario-button type="primary">Primary Button</ontario-button> -->
      </div>
    </ng-container>

    <ng-container *ngIf="isReadOnlyView">
      <h1>{{ "userForm.readonly.title" | translate | async }}</h1>

      <!-- back to users button -->
      <!-- <ng-container [ngTemplateOutlet]="formTemplateUsersNavigater"></ng-container> -->

      <div *ngIf="user?.form" class="ontario-row full-width">
        <ng-container *ngTemplateOutlet="formTemplate; context: { readonly: true }"></ng-container>
      </div>

      <!-- buttons -->
      <div class="ontario-row full-width">
        <button (click)="printForm()" class="ontario-button ontario-button--secondary">
          {{ "userForm.printForm.btn" | translate | async }}
        </button>
        <button [routerLink]="['/user/', user?.model?.id, 'edit']" class="ontario-button ontario-button--secondary">
          {{ "userForm.edit.btn" | translate | async }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="isEditView">
      <h1>{{ "userForm.update.title" | translate | async }}</h1>

      <!-- back to users button -->
      <!-- <ng-container [ngTemplateOutlet]="formTemplateUsersNavigater"></ng-container> -->

      <!-- form input errors from server -->
      <ng-container *ngIf="shouldShowErrors" [ngTemplateOutlet]="errorNotificationTemplate"></ng-container>

      <div *ngIf="user?.form" class="ontario-row full-width">
        <ng-container *ngTemplateOutlet="formTemplate; context: { editing: true }"></ng-container>
      </div>

      <!-- buttons -->
      <div class="ontario-row full-width">
        <button (click)="sendForm()" class="ontario-button ontario-button--primary">
          {{ "userForm.update.btn" | translate | async }}
        </button>
        <!-- <button (click)="resetForm()" class="ontario-button ontario-button--secondary">{{ "userForm.resetForm.btn" |
        translate | async }}</button> -->
        <button [routerLink]="['/users']" class="ontario-button ontario-button--secondary">
          {{ "userForm.cancel.btn" | translate | async }}
        </button>
      </div>

      <div class="ontario-row full-width" style="margin-bottom: 20px !important">
      <h1>{{ "user.history.title" | translate | async }}</h1>
    </div>

      <!-- paginations -->

      <div class="example-container" style="position: relative; width: 100%" [class.loading]="loadingResults">
      <ods-spinner *ngIf="loadingResults" [fullScreen]="false">
        {{ "app.spinner.text" | translate | async }}
      </ods-spinner>

      <div class="example-table-container" style="overflow: auto">
        <table mat-table [dataSource]="dataSource?.data.length > 0 ? dataSource : emptyData" class="example-table"
          matSort #sort="matSort">
          <ng-container matColumnDef="modifiedDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by modifiedDate">
              {{ "user.table.column.recordDate.title" | translate | async }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.modifiedDate }}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by action">
              {{ "user.table.column.action.title" | translate | async }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.action }}</td>
          </ng-container>

          <ng-container matColumnDef="modifiedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by modifiedBy">
              {{ "user.table.column.modifiedBy.title" | translate | async }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row?.modifiedBy }}</td>
          </ng-container>

          <ng-container matColumnDef="modifiedValues">
            <th mat-header-cell *matHeaderCellDef>
              {{ "user.table.column.modifiedValues.title" | translate | async }}
            </th>
            <td mat-cell *matCellDef="let row">
              <div *ngFor="let modifiedValue of row?.modifiedValues">
                <span><b> {{ modifiedValue.fieldName }}: </b></span><br />
                <span><b>newValue:</b> {{ modifiedValue.newValue }}</span><br />
                <span><b>oldValue:</b> {{ modifiedValue.oldValue }}</span><br />
                <br />
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="empty-row">
            <mat-cell *matCellDef="let element">{{
              "table.noData.text" | translate | async
              }}</mat-cell>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="
              let row;
              columns: dataSource?.data?.length > 0
                ? displayedColumns
                : ['empty-row']
            " [class.ontario-table-row--highlight]="row?.highlighted"></tr>
        </table>
      </div>

      <mat-paginator #paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="5"
        showFirstLastButtons></mat-paginator>
    </div>
    </ng-container>
  </ng-container>

  <ng-template #formTemplate let-readonly="readonly" let-editing="editing">
    <div class="ontario-row full-width">
      <div id="describedBy" hidden>{{ "user.pageDescription.text" | translate | async }}</div>
      <form novalidate [formGroup]="user.form" class="ontario-row full-width">
        <div class="ontario-row full-width">
          <!-- email -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('loginEmail').required" formCtrlId="{{ field('loginEmail').id }}">
                <span name>{{
                  field("loginEmail").label | translate | async
                  }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
      !readonly &&
      field('loginEmail').required &&
      formCtrl('loginEmail')?.touched &&
      formCtrl('loginEmail')?.hasError('required')
    " formCtrlId="{{ field('loginEmail').id }}">
                {{
                field("loginEmail").getValidator("required").message
                | translate
                : [
                field("loginEmail").label
                | translate
                | async
                | lowercase
                ]
                | async
                }}
              </odsf-error>

              <odsf-error *ngIf="
      !readonly &&
      field('loginEmail').maxLength &&
      formCtrl('loginEmail')?.hasError('maxlength')
    " formCtrlId="{{ field('loginEmail').id }}">
                {{
                field("loginEmail").getValidator("maxLength").message
                | translate
                : [
                field("loginEmail").label | translate | async,
                formCtrl("loginEmail")?.errors["maxlength"]
                .requiredLength
                ]
                | async
                }}
              </odsf-error>
              <odsf-input formCtrlId="{{ field('loginEmail').id }}" formCtrlName="{{ field('loginEmail').name }}"
                [formCtrl]="formCtrl('loginEmail')" [required]="field('loginEmail').required" >
              </odsf-input>

            </div>
          </div>
        </div>
        <div class="ontario-row full-width">
          <!-- first name -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">

              <odsf-label [required]="field('firstName').required" formCtrlId="{{ field('firstName').id }}">
                <span name>{{
                  field("firstName").label | translate | async
                  }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
                !readonly &&
                field('firstName').required &&
                formCtrl('firstName')?.touched &&
                formCtrl('firstName')?.hasError('required')
              " formCtrlId="{{ field('firstName').id }}">
                {{
                field("firstName").getValidator("required").message
                | translate
                : [field("firstName").label | translate | async | lowercase]
                | async
                }}
              </odsf-error>

              <odsf-error *ngIf="
                !readonly &&
                field('firstName').maxLength &&
                formCtrl('firstName')?.hasError('maxlength')
              " formCtrlId="{{ field('firstName').id }}">
                {{
                field("firstName").getValidator("maxLength").message
                | translate
                : [
                field("firstName").label | translate | async,
                formCtrl("firstName")?.errors["maxlength"]
                .requiredLength
                ]
                | async
                }}
              </odsf-error>

              <odsf-input formCtrlId="{{ field('firstName').id }}" formCtrlName="{{ field('firstName').name }}"
                [formCtrl]="formCtrl('firstName')" [required]="field('firstName').required" >
              </odsf-input>
            </div>
          </div>

          <!-- last name -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('lastName').required" formCtrlId="{{ field('lastName').id }}">
                <span name>{{
                  field("lastName").label | translate | async
                  }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
                !readonly &&
                field('lastName').required &&
                formCtrl('lastName')?.touched &&
                formCtrl('lastName')?.hasError('required')
              " formCtrlId="{{ field('lastName').id }}">
                {{
                field("lastName").getValidator("required").message
                | translate
                : [field("lastName").label | translate | async | lowercase]
                | async
                }}
              </odsf-error>

              <odsf-error *ngIf="
                !readonly &&
                field('lastName').maxLength &&
                formCtrl('lastName')?.hasError('maxlength')
              " formCtrlId="{{ field('lastName').id }}">
                {{
                field("lastName").getValidator("maxLength").message
                | translate
                : [
                field("lastName").label | translate | async,
                formCtrl("lastName")?.errors["maxlength"].requiredLength
                ]
                | async
                }}
              </odsf-error>

              <odsf-input formCtrlId="{{ field('lastName').id }}" formCtrlName="{{ field('lastName').name }}"
                [formCtrl]="formCtrl('lastName')" [required]="field('lastName').required" >
              </odsf-input>

            </div>
          </div>
        </div>

        <div class="ontario-row full-width">

          <!-- title -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('titleCode').required" formCtrlId="{{ field('titleCode').id }}">
                <span name>{{
                  field("titleCode").label | translate | async
                  }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
                !readonly &&
                field('titleCode').required &&
                formCtrl('titleCode')?.touched &&
                formCtrl('titleCode')?.hasError('required')
              " formCtrlId="{{ field('titleCode').id }}">
                {{
                field("titleCode").getValidator("required").message
                | translate
                : [field("titleCode").label | translate | async | lowercase]
                | async
                }}
              </odsf-error>

              <odsf-error *ngIf="
                !readonly &&
                field('titleCode').maxLength &&
                formCtrl('titleCode')?.hasError('maxlength')
              " formCtrlId="{{ field('titleCode').id }}">
                {{
                field("titleCode").getValidator("maxLength").message
                | translate
                : [
                field("titleCode").label | translate | async,
                formCtrl("titleCode")?.errors["maxlength"]
                .requiredLength
                ]
                | async
                }}
              </odsf-error>

              <odsf-select formCtrlId="{{ field('titleCode').id }}" formCtrlName="{{ field('titleCode').name }}"
                [formCtrl]="formCtrl('titleCode')" [required]="field('titleCode').required" >
                <option value="" selected>Select</option>
                <option *ngFor="
                  let item of optionItems('title') | orderBy : 'displayOrder';
                  let i = index;
                  trackBy: trackByFn
                " [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>

            </div>
          </div>

          <!-- rank -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <odsf-label [required]="field('rank').required" formCtrlId="{{ field('rank').id }}">
                <span name>{{ field("rank").label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
                !readonly &&
                field('rank').required &&
                formCtrl('rank')?.touched &&
                formCtrl('rank')?.hasError('required')
              " formCtrlId="{{ field('rank').id }}">
                {{
                field("rank").getValidator("required").message
                | translate
                : [field("rank").label | translate | async | lowercase]
                | async
                }}
              </odsf-error>

              <odsf-error *ngIf="
                !readonly &&
                field('rank').maxLength &&
                formCtrl('rank')?.hasError('maxlength')
              " formCtrlId="{{ field('rank').id }}">
                {{
                field("rank").getValidator("maxLength").message
                | translate
                : [
                field("rank").label | translate | async,
                formCtrl("rank")?.errors["maxlength"].requiredLength
                ]
                | async
                }}
              </odsf-error>

              <odsf-select formCtrlId="{{ field('rank').id }}" formCtrlName="{{ field('rank').name }}"
                [formCtrl]="formCtrl('rank')" [required]="field('rank').required" >
                <option value="" selected>Select</option>
                <option *ngFor="
                  let item of optionItems('rank') | orderBy : 'displayOrder';
                  let i = index;
                  trackBy: trackByFn
                " [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>

            </div>
          </div>

        </div>

        <div class="ontario-row full-width" id="service-role-label">
          <!-- role -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              <div class="ontario-label">
                <span name>{{
                  field("serviceRole").label | translate | async
                  }}</span>
                </div>

              <odsf-error *ngIf="
               !readonly &&
               field('serviceRole').required &&
               formCtrl('serviceRole')?.touched &&
               formCtrl('serviceRole')?.hasError('required')
             " formCtrlId="{{ field('serviceRole').id }}">
                {{
                field("serviceRole").getValidator("required").message
                | translate
                : [
                field("serviceRole").label
                | translate
                | async
                | lowercase
                ]
                | async
                }}
              </odsf-error>

              <div class="ontario-input">
                <mat-select id="{{ field('serviceRole').id }}" [compareWith]="compareWithFn" [formControl]="formCtrl('serviceRole')" multiple (selectionChange)="handleRoleSelectionChange($event)"  aria-labelledby="service-role-label">
                  <mat-option *ngFor="
                   let item of filteredRoles('serviceRole');
                   let i = index;
                   trackBy: trackByFn
                 " [value]="{id:+item.id, 'roleCode':item.name['en'],'serviceCode':'CJDD'}"
                 [disabled]="isOptionEnabled(item)">
                 {{ item.id > 4 ? 'Basic' : item.name[lang$ | async] }}
                  </mat-option>
                </mat-select>
              </div>

            </div>
          </div>
          <!-- has badge checkbox -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">

            <div class="ontario-form-group">

              <odsf-label [required]="field('hasBadge').required" formCtrlId="{{ field('badgeNumber').id }}">
                <span name> {{ field("hasBadge").label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
      !readonly &&
      field('hasBadge').required &&
      formCtrl('hasBadge')?.hasError('required')
    " formCtrlId="{{ field('hasBadge').id }}">
                {{
                field("hasBadge").getValidator("required").message
                | translate
                : [field("hasBadge").label | translate | async | lowercase]
                | async
                }}
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-error>

              <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">

                <odsf-radio formCtrlId="{{ field('hasBadge').id }}" formCtrlName="{{ field('hasBadge').name }}"
                  [formCtrl]="formCtrl('hasBadge')" [required]="field('hasBadge').required"
                  [optionItems]="['radio.value.yes'| translate | async, 'radio.value.no'| translate | async ]"
                  [values]=badgeRadio [inOneLine]="true" [radioValue]="true">

                </odsf-radio>

              </div>

              <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-12 ontario-padding-left-0-!">

                <odsf-error *ngIf="
  !readonly &&
  formCtrl('hasBadge')?.value === 'true' &&
  field('badgeNumber').requiredIf &&
  formCtrl('badgeNumber')?.touched &&
  formCtrl('badgeNumber')?.hasError('required')
" formCtrlId="{{ field('badgeNumber').id }}">
                  {{
                  field("badgeNumber").getValidator("requiredIf").message
                  | translate
                  : [
                  field("badgeNumber").label
                  | translate
                  | async
                  | lowercase
                  ]
                  | async
                  }}
                </odsf-error>

                <odsf-error *ngIf="
  !readonly &&
  formCtrl('hasBadge')?.value === 'true' &&
  field('badgeNumber').existance &&
  formCtrl('badgeNumber')?.hasError('existance')
" formCtrlId="{{ field('badgeNumber').id }}">
                  {{
                  field("badgeNumber").getValidator("existance").message
                  | translate
                  : [
                  field("badgeNumber").label
                  | translate
                  | async
                  | lowercase,
                  formCtrl("badgeNumber")?.value
                  ]
                  | async
                  }}
                </odsf-error>

                <odsf-error *ngIf="
  !readonly &&
  formCtrl('hasBadge')?.value === 'true' &&
  field('badgeNumber').pattern &&
  formCtrl('badgeNumber')?.hasError('pattern')
" formCtrlId="{{ field('badgeNumber').id }}">
                  {{
                  field("badgeNumber").getValidator("pattern").message
                  | translate
                  : [
                  field("badgeNumber").label
                  | translate
                  | async
                  | lowercase,
                  "(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)"
                  ]
                  | async
                  }}
                </odsf-error>

                <odsf-error *ngIf="
  !readonly &&
  formCtrl('hasBadge')?.value === 'true' &&
  field('badgeNumber').maxLength &&
  formCtrl('badgeNumber')?.hasError('maxlength')
" formCtrlId="{{ field('badgeNumber').id }}">
                  {{
                  field("badgeNumber").getValidator("maxLength").message
                  | translate
                  : [
                  field("badgeNumber").label | translate | async,
                  formCtrl("badgeNumber")?.errors["maxlength"]
                  .requiredLength
                  ]
                  | async
                  }}
                </odsf-error>
                <odsf-input formCtrlId="{{ field('badgeNumber').id }}"
                  formCtrlName="{{ field('badgeNumber').name }}" [formCtrl]="formCtrl('badgeNumber')"
                  [required]="field('badgeNumber').required" >
                </odsf-input>
              </div>
            </div>
          </div>

        </div>

        <div class="ontario-row full-width" *ngIf="lowestRoleId === 1">


          <!-- court region code  -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!" >
            <div class="ontario-form-group">
              <odsf-label [required]="field('courtRegionCode').required" formCtrlId="{{ field('courtRegionCode').id }}">
                <span name>{{
                  field("courtRegionCode").label | translate | async
                  }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error
                *ngIf="!readonly && field('courtRegionCode').required && formCtrl('courtRegionCode')?.touched && formCtrl('courtRegionCode')?.hasError('required')"
                formCtrlId="{{ field('courtRegionCode').id }}">
                {{ field('courtRegionCode').getValidator("required").message | translate :
                [field('courtRegionCode').label
                | translate | async | lowercase] | async }}
              </odsf-error>

              <odsf-select formCtrlId="{{ field('courtRegionCode').id }}"
                formCtrlName="{{ field('courtRegionCode').name }}" [formCtrl]="formCtrl('courtRegionCode')"
                [required]="field('courtRegionCode').required">
                <option value="" selected>Select</option>
                <option *ngFor="
        let item of optionItems('region') | orderBy : 'displayOrder';
        let i = index;
        trackBy: trackByFn
      " [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>
              

            </div>
          </div>

          <!-- Home agency -->
          <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!" >
            <div class="ontario-form-group">
              <odsf-label [required]="field('agency').required" formCtrlId="{{ field('agency').id }}">
                <span name>{{ field("agency").label | translate | async }}</span>
                <span flag>({{ "form.field.required.text" | translate | async }})</span>
              </odsf-label>

              <odsf-error *ngIf="
                !readonly &&
                field('agency').required &&
                formCtrl('agency')?.touched &&
                formCtrl('agency')?.hasError('required')
              " formCtrlId="{{ field('agency').id }}">
                {{
                field("agency").getValidator("required").message
                | translate
                : [field("agency").label | translate | async | lowercase]
                | async
                }}
              </odsf-error>

              <odsf-error *ngIf="
                !readonly &&
                field('agency').maxLength &&
                formCtrl('agency')?.hasError('maxlength')
              " formCtrlId="{{ field('agency').id }}">
                {{
                field("agency").getValidator("maxLength").message
                | translate
                : [
                field("agency").label | translate | async,
                formCtrl("agency")?.errors["maxlength"].requiredLength
                ]
                | async
                }}
              </odsf-error>

              <odsf-select formCtrlId="{{ field('agency').id }}" formCtrlName="{{ field('agency').name }}"
                [formCtrl]="formCtrl('agency')" [required]="field('agency').required">
                <option value="" selected>Select</option>
                <option *ngFor="
                  let item of optionItems('agency') | orderBy : 'displayOrder';
                  let i = index;
                  trackBy: trackByFn
                " [value]="item.id">
                  {{ item.name[lang$ | async] }}
                </option>
              </odsf-select>

            </div>
          </div>
        </div>
        <div class="ontario-row full-width">
          <!-- isActive toggle -->
          <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">
              
              <div class="ontario-label">
                <span name>{{ field("isActive").label | translate | async }}</span>
              </div>

              <mat-slide-toggle id="{{ field('isActive').id }}" name="{{ field('isActive').name }}" [formControl]="formCtrl('isActive')" >
                {{"userForm.field.isActive.subheader" | translate | async}}
              </mat-slide-toggle>

            </div>
          </div>
        </div>
        <!-- Not for this release -->
        <!-- <div class="ontario-row full-width"
        *ngIf="formCtrl('serviceRole').value && (formCtrl('serviceRole').value[0]?.id  > 1 )"> -->
        <!-- Home or Secondment -->
        <!-- <div class="ontario-columns ontario-large-6 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">

            <odsf-label [required]="field('agencyType').required" formCtrlId="{{ field('agencyType')}}">
              <span name> {{ field("agencyType").label | translate | async }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="
                !readonly &&
                field('agencyType').required &&
                formCtrl('agencyType')?.hasError('required')
              " formCtrlId="{{ field('agencyType').id }}">
              {{
              field("agencyType").getValidator("required").message
              | translate
              : [field("agencyType").label | translate | async | lowercase]
              | async
              }}
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-error>

            <odsf-radio formCtrlId="{{ field('agencyType').id }}" formCtrlName="{{ field('agencyType').name }}"
              [formCtrl]="formCtrl('agencyType')" [required]="field('agencyType').required"
              [optionItems]="['userForm.agencyType.home'| translate | async, 'userForm.agencyType.secondment'| translate | async ]"
              [values]=agencyTypeRadio [inOneLine]="true">

            </odsf-radio>

          </div>
        </div> -->
        <!-- </div> -->

        <!-- <div class="ontario-row full-width"
          >
         
        </div> -->

        <!-- <div class="ontario-row full-width"> -->
        <!-- is eSearch checkbox -->
        <!-- <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="
                !readonly &&
                field('isSeconded').required &&
                formCtrl('isSeconded')?.hasError('required')
              " formCtrlId="{{ field('isSeconded').id }}">
              {{
              field("isSeconded").getValidator("required").message
              | translate
              : [
              field("isSeconded").label
              | translate
              | async
              | lowercase
              ]
              | async
              }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('isSeconded').id }}" formCtrlName="{{ field('isSeconded').name }}"
              [formCtrl]="formCtrl('isSeconded')" [required]="field('isSeconded').required"> -->
        <!-- {{ field("isSeconded").label | translate | async }} -->
        <!-- {{'userForm.field.isESearch.name' | json}} -->
        <!-- {{ "Is the officer enrolled into ESearch warrant?" }}
            </odsf-checkbox>
          </div>
        </div>
        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div> -->

        <!-- <div class="ontario-row full-width"> -->
        <!-- is seconded checkbox -->
        <!-- <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-error *ngIf="
                !readonly &&
                field('isSeconded').required &&
                formCtrl('isSeconded')?.hasError('required')
              " formCtrlId="{{ field('isSeconded').id }}">
              {{
              field("isSeconded").getValidator("required").message
              | translate
              : [
              field("isSeconded").label
              | translate
              | async
              | lowercase
              ]
              | async
              }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('isSeconded').id }}" formCtrlName="{{ field('isSeconded').name }}"
              [formCtrl]="formCtrl('isSeconded')" [required]="field('isSeconded').required">
              {{ field("isSeconded").label | translate | async }}
            </odsf-checkbox>
          </div>
        </div>
      </div> -->

        <!-- <div class="ontario-row full-width"> -->
        <!-- seconded agency code -->
        <!-- <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('secondedAgencyCode').required"
              formCtrlId="{{ field('secondedAgencyCode').id }}">
              <span name>{{
                field("secondedAgencyCode").label | translate | async
                }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedAgencyCode').requiredIf &&
                formCtrl('secondedAgencyCode')?.hasError('required')
              " formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{
              field("secondedAgencyCode").getValidator("requiredIf").message
              | translate
              : [
              field("secondedAgencyCode").label
              | translate
              | async
              | lowercase
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedAgencyCode').existance &&
                formCtrl('secondedAgencyCode')?.hasError('existance')
              " formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{
              field("secondedAgencyCode").getValidator("existance").message
              | translate
              : [
              field("secondedAgencyCode").label
              | translate
              | async
              | lowercase,
              formCtrl("secondedAgencyCode")?.value
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedAgencyCode').pattern &&
                formCtrl('secondedAgencyCode')?.hasError('pattern')
              " formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{
              field("secondedAgencyCode").getValidator("pattern").message
              | translate
              : [
              field("secondedAgencyCode").label
              | translate
              | async
              | lowercase,
              "(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)"
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedAgencyCode').maxLength &&
                formCtrl('secondedAgencyCode')?.hasError('maxlength')
              " formCtrlId="{{ field('secondedAgencyCode').id }}">
              {{
              field("secondedAgencyCode").getValidator("maxLength").message
              | translate
              : [
              field("secondedAgencyCode").label | translate | async,
              formCtrl("secondedAgencyCode")?.errors["maxlength"]
              .requiredLength
              ]
              | async
              }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('secondedAgencyCode').id }}"
              formCtrlName="{{ field('secondedAgencyCode').name }}" [formCtrl]="formCtrl('secondedAgencyCode')"
              [required]="field('secondedAgencyCode').required" [disabled]="!formCtrl('isSeconded')?.value">
            </odsf-input>
          </div>
        </div> -->

        <!-- seconded email -->
        <!-- <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('secondedEmail').required" formCtrlId="{{ field('secondedEmail').id }}">
              <span name>{{
                field("secondedEmail").label | translate | async
                }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedEmail').requiredIf &&
                formCtrl('secondedEmail')?.hasError('required')
              " formCtrlId="{{ field('secondedEmail').id }}">
              {{
              field("secondedEmail").getValidator("requiredIf").message
              | translate
              : [
              field("secondedEmail").label
              | translate
              | async
              | lowercase
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedEmail').existance &&
                formCtrl('secondedEmail')?.hasError('existance')
              " formCtrlId="{{ field('secondedEmail').id }}">
              {{
              field("secondedEmail").getValidator("existance").message
              | translate
              : [
              field("secondedEmail").label
              | translate
              | async
              | lowercase,
              formCtrl("secondedEmail")?.value
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedEmail').pattern &&
                formCtrl('secondedEmail')?.hasError('pattern')
              " formCtrlId="{{ field('secondedEmail').id }}">
              {{
              field("secondedEmail").getValidator("pattern").message
              | translate
              : [
              field("secondedEmail").label
              | translate
              | async
              | lowercase,
              "(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)"
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('secondedEmail').maxLength &&
                formCtrl('secondedEmail')?.hasError('maxlength')
              " formCtrlId="{{ field('secondedEmail').id }}">
              {{
              field("secondedEmail").getValidator("maxLength").message
              | translate
              : [
              field("secondedEmail").label | translate | async,
              formCtrl("secondedEmail")?.errors["maxlength"]
              .requiredLength
              ]
              | async
              }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('secondedEmail').id }}" formCtrlName="{{ field('secondedEmail').name }}"
              [formCtrl]="formCtrl('secondedEmail')" [required]="field('secondedEmail').required"
              [disabled]="!formCtrl('isSeconded')?.value">
            </odsf-input>
          </div>
        </div> -->

        <!-- <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div> -->

        <!-- <div *ngIf="editing" class="ontario-row full-width">
         is active checkbox
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">

            <odsf-error *ngIf="
                !readonly &&
                field('isActive').required &&
                formCtrl('isActive')?.hasError('required')
              " formCtrlId="{{ field('isActive').id }}">
              {{
              field("isActive").getValidator("required").message
              | translate
              : [field("isActive").label | translate | async | lowercase]
              | async
              }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('isActive').id }}" formCtrlName="{{ field('isActive').name }}"
              [formCtrl]="formCtrl('isActive')" [required]="field('isActive').required">
              {{ field("isActive").label | translate | async }}
            </odsf-checkbox>

          </div>
        </div>

       deactivation reason 
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('deactivationReason').required"
              formCtrlId="{{ field('deactivationReason').id }}">
              <span name>{{
                field("deactivationReason").label | translate | async
                }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="
                !readonly &&
                field('deactivationReason').requiredIf &&
                formCtrl('deactivationReason')?.hasError('required')
              " formCtrlId="{{ field('deactivationReason').id }}">
              {{
              field("deactivationReason").getValidator("requiredIf").message
              | translate
              : [
              field("deactivationReason").label
              | translate
              | async
              | lowercase
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('deactivationReason').existance &&
                formCtrl('deactivationReason')?.hasError('existance')
              " formCtrlId="{{ field('deactivationReason').id }}">
              {{
              field("deactivationReason").getValidator("existance").message
              | translate
              : [
              field("deactivationReason").label
              | translate
              | async
              | lowercase,
              formCtrl("deactivationReason")?.value
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('deactivationReason').pattern &&
                formCtrl('deactivationReason')?.hasError('pattern')
              " formCtrlId="{{ field('deactivationReason').id }}">
              {{
              field("deactivationReason").getValidator("pattern").message
              | translate
              : [
              field("deactivationReason").label
              | translate
              | async
              | lowercase,
              "(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)"
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('deactivationReason').maxLength &&
                formCtrl('deactivationReason')?.hasError('maxlength')
              " formCtrlId="{{ field('deactivationReason').id }}">
              {{
              field("deactivationReason").getValidator("maxLength").message
              | translate
              : [
              field("deactivationReason").label | translate | async,
              formCtrl("deactivationReason")?.errors["maxlength"]
              .requiredLength
              ]
              | async
              }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('deactivationReason').id }}"
              formCtrlName="{{ field('deactivationReason').name }}" [formCtrl]="formCtrl('deactivationReason')"
              [required]="field('deactivationReason').required" [disabled]="formCtrl('isActive')?.value">
            </odsf-input>

          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div> -->

        <!-- <div *ngIf="editing" class="ontario-row full-width">
        read only checkbox
        <div class="ontario-columns ontario-large-4 ontario-medium-5 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">

            <odsf-error *ngIf="
                !readonly &&
                field('readOnly').required &&
                formCtrl('readOnly')?.hasError('required')
              " formCtrlId="{{ field('readOnly').id }}">
              {{
              field("readOnly").getValidator("required").message
              | translate
              : [field("readOnly").label | translate | async | lowercase]
              | async
              }}
            </odsf-error>

            <odsf-checkbox formCtrlId="{{ field('readOnly').id }}" formCtrlName="{{ field('readOnly').name }}"
              [formCtrl]="formCtrl('readOnly')" [required]="field('readOnly').required">
              {{ field("readOnly").label | translate | async }}
            </odsf-checkbox>

          </div>
        </div>

        deactivation reason
        <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
          <div class="ontario-form-group">
            <odsf-label [required]="field('readOnlyReason').required" formCtrlId="{{ field('readOnlyReason').id }}">
              <span name>{{
                field("readOnlyReason").label | translate | async
                }}</span>
              <span flag>({{ "form.field.required.text" | translate | async }})</span>
            </odsf-label>

            <odsf-error *ngIf="
                !readonly &&
                field('readOnlyReason').requiredIf &&
                formCtrl('readOnlyReason')?.hasError('required')
              " formCtrlId="{{ field('readOnlyReason').id }}">
              {{
              field("readOnlyReason").getValidator("requiredIf").message
              | translate
              : [
              field("readOnlyReason").label
              | translate
              | async
              | lowercase
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('readOnlyReason').existance &&
                formCtrl('readOnlyReason')?.hasError('existance')
              " formCtrlId="{{ field('readOnlyReason').id }}">
              {{
              field("readOnlyReason").getValidator("existance").message
              | translate
              : [
              field("readOnlyReason").label
              | translate
              | async
              | lowercase,
              formCtrl("readOnlyReason")?.value
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('readOnlyReason').pattern &&
                formCtrl('readOnlyReason')?.hasError('pattern')
              " formCtrlId="{{ field('readOnlyReason').id }}">
              {{
              field("readOnlyReason").getValidator("pattern").message
              | translate
              : [
              field("readOnlyReason").label
              | translate
              | async
              | lowercase,
              "(e.g. bb799073-1ddf-4552-9cb4-6a4fffc8d062)"
              ]
              | async
              }}
            </odsf-error>

            <odsf-error *ngIf="
                !readonly &&
                field('readOnlyReason').maxLength &&
                formCtrl('readOnlyReason')?.hasError('maxlength')
              " formCtrlId="{{ field('readOnlyReason').id }}">
              {{
              field("readOnlyReason").getValidator("maxLength").message
              | translate
              : [
              field("readOnlyReason").label | translate | async,
              formCtrl("readOnlyReason")?.errors["maxlength"]
              .requiredLength
              ]
              | async
              }}
            </odsf-error>

            <odsf-input formCtrlId="{{ field('readOnlyReason').id }}" formCtrlName="{{ field('readOnlyReason').name }}"
              [formCtrl]="formCtrl('readOnlyReason')" [required]="field('readOnlyReason').required"
              [disabled]="!formCtrl('readOnly')?.value">
            </odsf-input>

          </div>
        </div>

        <div class="ontario-columns ontario-large-4 ontario-medium-3 ontario-small-0 ontario-padding-left-0-!"></div>
      </div> -->
        <ng-container *ngIf="displayEnrolledApp(formCtrl('serviceRole').value)">
          <div class="ontario-row full-width">
            <div class="ontario-row full-width">
              <div
                class="margin-top-15 ontario-columns ontario-large-6 ontario-medium-6 ontario-small-6 ontario-padding-left-0-!">

                <div class="ontario-label">
                  <span name>{{
                    "officer.enrolledService.title.text" | translate | async
                    }}</span>
                  </div>
              </div>
              <div class="ontario-columns ontario-large-6 ontario-medium-6 ontario-small-6 ontario-padding-left-0-!">

                <button id=addButton (click)="addServices()" 
                  class="ontario-button ontario-button--secondary" [disabled]="enrolledServicesList?.length === appServicesList?.length || selectedApp?.length < enrolledServicesList?.length">
                  {{ "user.button.add.text" | translate | async }}
                </button>
              </div>
            </div>
            <div class="ontario-columns ontario-large-12 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
              <div class="ontario-form-group">
                <div class="example-table-container" style="overflow: auto">
                  <table class="services-table">
                    <thead>
                      <tr>
                        <th>{{"user.enrolledServices.table.column.application" | translate | async}}</th>
                        <th>{{"user.enrolledServices.table.column.applicationSpecificRole" | translate | async}}</th>
                        <th><div aria-label="Delete application" hidden><span>Delete application</span></div></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let row of enrolledServicesList; let i = index">
                        <td>
                          <div class="ontario-input">
                          <mat-select [(value)]="row.selectedValue" (selectionChange)="onAppChange($event, row, i)">
                          <mat-option *ngFor="let option of filteredApps(row, i)  || []" [value]="option.serviceCode " > {{option.description}}</mat-option>
                        </mat-select>
                      </div>
                        </td>
                        <td>
                          <div class="ontario-input">
                            <mat-select [(value)]="row.selectedRoleId" (selectionChange)="onAppRoleAdded($event, i)">
                            <mat-option *ngFor="let option of row.roleCode" [value]=" option?.id " > Officer - {{option?.roleCode}}</mat-option>
                          </mat-select>
                        </div>
                        </td>
                        <td class="icon-cell">
                          <a class="ontario-__link mat-column-actions-btn" aria-label="Delete application" (click)="deleteApp(i, row.selectedValue)">
                            <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false"
                              sol:category="secondary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                              <use
                                href="assets/ontario-design-system/icons/ontario-icons-secondary.svg#ontario-icon-delete">
                              </use>
                            </svg>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        
        <div class="ontario-row full-width">
          <!-- declare checkbox -->
          <div class="ontario-columns ontario-large-10 ontario-medium-12 ontario-small-12 ontario-padding-left-0-!">
            <div class="ontario-form-group">

              <odsf-error *ngIf="
                !readonly &&
                field('declare').touched &&
                field('declare').requiredTrue &&
                formCtrl('declare')?.touched &&
                formCtrl('declare')?.hasError('required')
              " formCtrlId="{{ field('declare').id }}">
                {{
                field("declare").getValidator("requiredTrue").message
                | translate
                | async
                }}
              </odsf-error>

              <odsf-checkbox formCtrlId="{{ field('declare').id }}" formCtrlName="{{ field('declare').name }}"
                [formCtrl]="formCtrl('declare')" [required]="field('declare').required">
                {{ field("declare").label | translate | async }}
              </odsf-checkbox>

            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #errorNotificationTemplate>
    <div class="ontario-row full-width">
      <div
        class="ontario-columns ontario-large-8 ontario-medium-10 ontario-small-12 ontario-padding-left-0-! ontario-padding-right-0-!">
        <ods-page-alert role="error">
          <ng-container title>{{
            "notification.error.title" | translate | async
            }}</ng-container>
          <ng-container body>
            <p>
              {{
              "notification.error.content.invalidFormFields" | translate | async
              }}
            </p>
            <ul>
              <li *ngFor="
                let msg of notifications?.error?.data;
                let i = index;
                trackBy: trackByFn
              ">
                <a href="#">{{ msg }}</a>
              </li>
            </ul>
          </ng-container>
        </ods-page-alert>
      </div>

      <div class="ontario-columns ontario-large-4 ontario-medium-2 ontario-small-0"></div>
    </div>
  </ng-template>

  <!-- <ng-template #formTemplateUsersNavigater>
  <div class="ontario-row full-width navigator">
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!">
      <div class="ontario-footer__links-container--inline">
        <span (click)="goToUsersPage()"><a>
            <h3>{{ "userForm.backToUsers.btn" | translate | async }}</h3>
          </a></span>
      </div>
    </div>
    <div class="ontario-columns ontario-large-4 ontario-medium-4 ontario-small-12 ontario-padding-left-0-!"></div>
  </div>

  <hr />
</ng-template> -->

</div>