import { NgModule } from '@angular/core';
import { DisableControlDirective } from './disable-control.directive';

const mods = [DisableControlDirective];

@NgModule({
  declarations: [...mods],
  exports: [...mods]
})
class FormInputsModule {}

export { DisableControlDirective, FormInputsModule };
