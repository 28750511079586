import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppCacheService } from '@app/core/cache';
import { LanguageService } from '@shared/language';
import { Observable, Subject, Subscription } from 'rxjs';

export enum PageView {
  Create = <any>'create',
  Update = <any>'update',
  Read = <any>'read'
}

export const getViewFromUrl = function (url) {
  const [root, formName, formId, viewName] = url.split('/');
  if (!formId) return PageView.Create;
  if (!viewName) return PageView.Read;
  return PageView.Update;
};

@Component({
  selector: 'app-base',
  template: ``
})
export abstract class BaseViewComponent implements OnInit, OnDestroy {
  lang$: Observable<string>;

  // this is an observable reserved for a spinner when loading
  isLoading$ = new Subject();

  // alive is used to kill component level subscriptions
  alive = true;

  view: any = {
    type: PageView.Read,
    isReady: false
  };

  // this subscription is reserved in case certain tricky case requires it, use alive if not needed
  subscription = new Subscription();
  subscriptions = [];

  // commonly injected services
  languageService: LanguageService;
  router: Router;
  appCache: AppCacheService;
  changeDetectorRef: ChangeDetectorRef;

  constructor(injector: Injector) {
    this.languageService = injector.get(LanguageService);
    this.router = injector.get(Router);
    this.appCache = injector.get(AppCacheService);
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
  }

  ngOnInit() {
    this.bindAppLevelObservables();
  }

  ngOnDestroy() {
    this.alive = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptions?.length) {
      this.subscriptions.map(s => s?.unsubscribe());
    }
  }

  trackByFn(index) {
    return index;
  }

  trackById(index, item) {
    if (!item) {
      return undefined;
    }
    return item.id !== undefined && item.id !== null ? item.id : item.get('id').value;
  }

  // bind to the app level observables
  private bindAppLevelObservables() {
    this.lang$ = this.languageService.language$;
  }

  protected register$(subscriptions) {
    this.subscriptions = subscriptions.map(s => s?.subscribe(data => this.updateView(data)));
    return this.subscriptions;
  }

  protected updateView(data?) {
    this.view.isReady = true;
    return this.changeDetectorRef.markForCheck();
  }
}
