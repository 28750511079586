export const API_HOST = 'ws.dev.justiceportal.jus.gov.on.ca';
export const API_DOMAIN = `https://${API_HOST}`;
export const API_ENDPOINT_ROOT = `${API_DOMAIN}/enf/auth`;
export const USER_ENDPOINT_ROOT = `${API_DOMAIN}/enf/user`;
export const REF_ENDPOINT_ROOT = `${API_DOMAIN}/enf/ref`;

export const AUTH = {
  AUTHORITY_ISSUER: 'https://stage.login.security.gov.on.ca',
  BASIC_TOKEN: "RVNXUG9saWNlUG9ydGFsQ2xpZW50OkVzQGFyY2hQMGwxY0A=",
  IS_SERVICE_AUTHORIZED: `${API_ENDPOINT_ROOT}/isAuthorizedService?email={email}&service={service}`,
  REQ_HEADER_DOMAIN_NAME: "ESearchWarrantPPortalDomain",
  CLIENT_ID: "ESWPolicePortalClient",
  SERVICE_NAME: "CJDD"
};

export const POLICE_PORTAL_HOST = 'dev.esw.officerportal.jus.gov.on.ca';
export const POLICE_PORTAL_DOMAIN = `https://${POLICE_PORTAL_HOST}`;


export const API_ENDPOINTS = {

  USER: `${USER_ENDPOINT_ROOT}/findAll`,
  USER_GET: `${USER_ENDPOINT_ROOT}/findById?id={userId}`,
  USER_GET_BY_EMAIL: `${USER_ENDPOINT_ROOT}/findByLoginEmail?email={email}`,
  USER_GET_BY_CERT_EMAIL: `${USER_ENDPOINT_ROOT}/findByCertEmail?email={email}`,
  USER_GET_BY_AGENCY: `${USER_ENDPOINT_ROOT}/findByAgency?agency={agency}`,
  USER_CREATE: `${USER_ENDPOINT_ROOT}/`,
  USER_SERVICE_ROLES: `${USER_ENDPOINT_ROOT}/serviceRoles`,
  USER_APP_ROLES: `${USER_ENDPOINT_ROOT}/appRoles`,
  USER_HISTORY:`${USER_ENDPOINT_ROOT}/auditLogs?id={id}`,
  
  REF_DATA_ALL: `${REF_ENDPOINT_ROOT}/retrieveAll`,
  REF_DATA_RANK: `${REF_ENDPOINT_ROOT}/retrieveRanks`,
  REF_DATA_TITLE: `${REF_ENDPOINT_ROOT}/retrieveTitles`,
  REF_DATA_ROLE: `${REF_ENDPOINT_ROOT}/retrieveRoles`,
  REF_DATA_AGENCY: `${REF_ENDPOINT_ROOT}/retrieveAgencies`,
  REF_DATA_REGION: `${REF_ENDPOINT_ROOT}/retrieveRegions`,

  MESSAGE_GET: `/whatIsThisUsedFor`,
  MESSAGE_CREATE: `/whatIsThisUsedFor`,

};

/* IDT - Integrated Development */
export const environment = {
  production: false,

  // i18n
  defaultLanguage: 'en',
  i18nDirectory: './assets/i18n/',

  // cache
  cacheLifespanInHours: 12,
  cacheMaxAge: 3600000, // 1 hour
  cacheableHttpMethods: ['GET'],
  cacheableUrls: [],

  // form
  validateForm: true,

  // auth
  redirectUri: '',
  accessDeniedRedirectUri: '',
  clientId: '',
  scope: '',
  logoutUrl: '',
  postLogoutRedirectUri: '',

  // api
  apiEndpoint: API_ENDPOINTS,

  // session timeout
  sessionIdleTimeoutInMins: 15,
  sessionIdleAlertLeadTimeInMins: 4
};
