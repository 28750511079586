import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataLookupService } from '@app/core';
import { AppCacheService } from '@app/core/cache';
import { environment } from '@environments/environment';
import { APP_REQUEST_CONTEXT } from '@shared/loading';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReferenceData } from '@app/core/app.constants';
import { ServerAuthorizedServiceModel, UserApiService } from '../auth';
import { transformer } from '../app-data-lookup.service';

@Injectable({
  providedIn: 'root'
})
export class APICacheService {
  constructor(
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private appCache: AppCacheService,
    private appDataService: AppDataLookupService,
    private userApiService: UserApiService
  ) {
    this.httpClient = new HttpClient(this.handler);
  }

  create(buildVersion: string): Observable<any> {
    const isNewBuild = buildVersion !== this.appCache.buildVersion;
    if (isNewBuild || this.shouldFetch) {
      return this.run().pipe(
        tap(() => {
          this.appCache.setTimestamp();
          this.appCache.setHash();
        })
      );
    } else {
      return this.cacheI18n(); // need to load i18n files on every reload, do something about this
    }
  }

  get shouldFetch() {
    return this.appCache.empty || this.appCache.expired || this.appCache.adding;
  }

  run(): Observable<any> {
    const cacheables = [
      //this.cacheRankOptions(),
      //this.cacheTitleOptions(),
      this.cacheServiceRoleOptions(),
      this.cacheAplicationRoleOptions(),
      //this.cacheAgencyOptions(),
      //this.cacheRegionOptions(),
      //this.cacheUserServiceRoles(),
      this.cacheAllReferenecenData()
    ];
    return forkJoin(cacheables);
  }

  private cacheI18n() {
    const en = environment.i18nDirectory + 'en.json';
    const fr = environment.i18nDirectory + 'fr.json';
    return forkJoin([
      this.httpClient.get(en, { context: APP_REQUEST_CONTEXT }),
      this.httpClient.get(fr, { context: APP_REQUEST_CONTEXT })
    ]).pipe(tap((res: any) => ([this.appCache.en, this.appCache.fr] = res)));
  }

  private cacheRankOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getRankOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.rankOptions = data)));
  }

  private cacheTitleOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getTitleOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.titleOptions = data)));
  }

  private cacheServiceRoleOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getServiceRoleOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.serviceRoleOptions = data)));
  }

  private cacheAplicationRoleOptions(): Observable<ServerAuthorizedServiceModel[]> {
    return this.appDataService.getApplicationRoleOptions().pipe(tap((data: ServerAuthorizedServiceModel[]) => (this.appCache.appRoleOptions = data)));
  }

  private cacheAgencyOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getAgencyOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.agencyOptions = data)));
  }

  private cacheRegionOptions(): Observable<ReferenceData[]> {
    return this.appDataService.getRegionOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.regionOptions = data)));
  }
  
  // private cacheCourtHouseOptions(): Observable<ReferenceData[]> {
  //   return this.appDataService.getCourtHouseOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.courtHouseOptions = data)));
  // }

  // private cacheUserServiceRoles() {
  //   return this.appDataService.getRoleOptions().pipe(tap((data: ReferenceData[]) => (this.appCache.userServiceRoleOptions = data)));
  // }

  private cacheAllReferenecenData() {
    return this.appDataService.getAllReferenceData().pipe(
      tap(res => this.appCache.rankOptions = res.filter(r => r.tableName == "RANK").map(transformer.toReferenceData) ),
      tap(res => this.appCache.titleOptions = res.filter(r => r.tableName == "TITLE").map(transformer.toReferenceData) ),
      tap(res => this.appCache.agencyOptions = res.filter(r => r.tableName == "AGENCY").map(transformer.toReferenceData) ),
      tap(res => this.appCache.regionOptions = res.filter(r => r.tableName == "REGION").map(transformer.toReferenceData) )
    );
  }

}
