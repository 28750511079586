import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent, AppCoreModule, AppRoutingModule } from '@app/core';
import { SessionTimeoutService } from '@app/core/auth';
import { AppFooterComponent, AppTopNavComponent } from '@app/core/components';
import { TranslatePipe } from '@app/core/i18n';
import { CallbackComponent, ErrorComponent, HomeComponent, LoginComponent, LogoutComponent, OfficerComponent, UnauthorizedComponent, UsersComponent } from '@app/view';
import { SharedModule } from '@shared/modules';
import { ApplicationAndRolesPipe } from '@shared/pipes/application-and-roles.pipe';

const viewComponents = [AppComponent, CallbackComponent, UnauthorizedComponent, LoginComponent, LogoutComponent, HomeComponent, OfficerComponent, UsersComponent, ErrorComponent];
const sharedViewComponents = [ApplicationAndRolesPipe, AppTopNavComponent, AppFooterComponent];

@NgModule({
  declarations: [...viewComponents, ...sharedViewComponents],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, AppCoreModule, SharedModule, MatDialogModule],
  providers: [
    TranslatePipe,
    { provide: APP_INITIALIZER, useFactory: () => () => null, deps: [SessionTimeoutService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
