import { HttpContext, HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from './loading.service';

export const APP_REQUEST_TOKEN = new HttpContextToken<boolean>(() => false);
export const APP_REQUEST_CONTEXT = new HttpContext().set(APP_REQUEST_TOKEN, true);

@Injectable({ providedIn: 'root' })
export class LoadingInterceptor implements HttpInterceptor {
  activeRequests = 0;

  constructor(private loadingService: LoadingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isAppRequest(req)) {
      if (this.activeRequests === 0) this.loadingService.startLoading();

      this.activeRequests++;

      return next.handle(req).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) this.loadingService.stopLoading();
        })
      );
    }

    return next.handle(req);
  }

  private isAppRequest(req: HttpRequest<any>): boolean {
    return req.context.get(APP_REQUEST_TOKEN) === true;
  }
}
