<div class="app-top-nav-container">
  <div class="ontario-header__container" [class.ontario-navigation--open]="isNavOpened">
    <header class="ontario-header" id="ontario-header">
      <div class="ontario-row ontario-row-without-max">
        <div class="ontario-header__logo-container ontario-columns ontario-small-2 ontario-medium-4 ontario-large-9">
          <a href="https://www.ontario.ca/page/government-ontario">
            <img role="img" class="ontario-show-for-medium"
              src="assets/ontario-design-system/logos/ontario-logo--desktop.svg" alt="Government of Ontario" />
            <img role="img" class="ontario-show-for-small-only"
              src="assets/ontario-design-system/logos/ontario-logo--mobile.svg" alt="Government of Ontario" />
          </a>
        </div>

        <form *ngIf="searchEnabled" name="searchForm" id="ontario-search-form-container" onsubmit="return false"
          class="ontario-header__search-container ontario-columns ontario-small-10 ontario-medium-offset-3 ontario-medium-6 ontario-large-offset-0 ontario-large-6"
          novalidate>
          <label for="ontario-search-input-field" class="ontario-show-for-sr">Search</label>
          <input type="text" name="search" id="ontario-search-input-field" autocomplete="off" aria-autocomplete="none"
            class="ontario-input ontario-header__search-input" required />
          <input class="ontario-header__search-reset" id="ontario-search-reset" type="reset" value=""
            aria-label="Clear field" />
          <button class="ontario-header__search-submit" id="ontario-search-submit" type="submit" onclick="doSearch()">
            <span class="ontario-show-for-sr">Submit</span>
            <svg class="ontario-icon" focusable="false" sol:category="primary" viewBox="0 0 24 24"
              preserveAspectRatio="xMidYMid meet">
              <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-search"></use>
            </svg>
          </button>
        </form>

        <div
          class="ontario-header__nav-right-container ontario-columns ontario-small-10 ontario-medium-8 ontario-large-6">
          <ng-container *ngIf="loggedIn">
            <button
              class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline">
              <abbr id="user-email" title="{{ userEmail }}" class="ontario-show-for-small-only"> {{ userEmail }} </abbr>
              <span id="user-email" class="ontario-show-for-medium"> {{ userEmail }} </span>
            </button>
          </ng-container>

          <div class="ontario-padding-right-8-!"></div>

          <button *ngIf="i18nEnabled" (click)="toggleLanguage()" href=""
            class="ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline">
            <abbr title="{{ langLabel }}" class="ontario-show-for-small-only"> {{ langLabelAbbr | uppercase }} </abbr>
            <span class="ontario-show-for-medium"> {{ langLabel }} </span>
          </button>

          <button *ngIf="searchEnabled"
            class="ontario-header__search-toggler ontario-header-button ontario-header-button--without-outline ontario-hide-for-large"
            id="ontario-header-search-toggler" aria-controls="ontario-search-form-container" type="button"
            aria-label="Search">
            <svg class="ontario-icon" focusable="false" sol:category="primary" viewBox="0 0 24 24"
              preserveAspectRatio="xMidYMid meet">
              <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-search"></use>
            </svg>
            <span class="ontario-show-for-medium ontario-show"> Search </span>
          </button>
        </div>
      </div>
    </header>

    <div class="ontario-application-subheader-menu__container">
      <section class="ontario-application-subheader">
        <div class="ontario-row ontario-row-without-max">
          <div class="ontario-columns ontario-small-12 ontario-application-subheader__container">
            <h1 class="ontario-application-subheader__heading ontario-large-12 ontario-medium-10 ontario-small-8">
              <a href="#">{{ title }} <span *ngIf="displayAgencyName()"> - {{userAgency | translate | async}}</span></a>
            </h1>            
            <ng-container *ngIf="loggedIn">
              <div *ngIf="!menuEnabled" class="ontario-application-subheader__menu-container">
                <ul class="ontario-application-subheader__menu">
                  
                  <li routerLinkActive="active" *ngIf="hasAdminAccess">
                    <a [routerLink]="['/users']">{{ "officer.userManagement.title.text" | translate | async }}</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="hasEnrolledServicesAccess">
                    <a [routerLink]="['/officer']">{{ "officer.enrolledService.title.text" | translate | async }}</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="isNotBusinessUser">
                    <a [routerLink]="['/profile', userId]">{{ "profile.readonly.title" | translate | async }}</a>
                  </li>
                  <li>
                    <a (click)="logout()" [routerLink]="['/']">{{ "menu.logout.text" | translate | async }}</a>
                  </li>

                </ul>
              </div>

              <div class="ontario-application-subheader__menu-container" *ngIf="menuEnabled && loggedIn">
                <button class="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline"
                  id="ontario-header-menu-toggler" aria-controls="ontario-navigation" aria-label="open menu"
                  (click)="toggleNav('open')" #openMenuBtn type="button">
                  <svg class="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-menu">
                    </use>
                  </svg>
                  <span>{{ "menu.btn.text" | translate | async }}</span>
                </button>

                <button class="ontario-header__menu-toggler ontario-header-button ontario-header-button--with-outline"
                  id="ontario-header-nav-toggler" aria-controls="ontario-navigation" (click)="toggleNav('close')"
                  #closeMenuBtn aria-label="close">
                  <svg class="ontario-icon" focusable="false" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use xlink:href="assets/ontario-design-system/icons/ontario-icons-primary.svg#ontario-icon-close">
                    </use>
                  </svg>
                  <span>{{ "menu.btn.text" | translate | async }}</span>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </section>

      <nav *ngIf="menuEnabled && loggedIn" #mainNav class="ontario-navigation" id="ontario-navigation"
        [class.ontario-navigation--is-ready]="menuEnabled" [class.ontario-navigation--open]="isNavOpened">
        <div class="ontario-navigation__container">
          <ul>
            <li *ngIf="isNotBusinessUser">
              <a [routerLink]="['/profile', userId]" (click)="toggleNav('close')">{{ "profile.readonly.title" | translate | async }}</a>
            </li>
            <li>
              <a [routerLink]="['/home']" (click)="toggleNav('close')">{{ "menu.home.text" | translate | async }}</a>
            </li>
            <li  *ngIf="hasEnrolledServicesAccess">
              <a [routerLink]="['/officer']" (click)="toggleNav('close')">{{ "menu.officer.text" | translate | async
                }}</a>
            </li>
            <li  *ngIf="hasAdminAccess">
              <a [routerLink]="['/users']" (click)="toggleNav('close')">{{ "menu.users.text" | translate | async }}</a>
            </li>
            <li>
              <a (click)="logout(); toggleNav('close')" [routerLink]="['/']" >{{ "menu.logout.text" | translate | async }}</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="ontario-overlay"></div>
</div>