import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { ErrorComponent, HomeComponent, LoginComponent, LogoutComponent, OfficerComponent, UnauthorizedComponent, UsersComponent } from '@app/view';
import { AuthGuard } from '@shared/guard/auth-guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: "officer", component: OfficerComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  {
    path: 'profile',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/profile/profile.module').then(m => m.ProfileModule) },
      { path: ':id', loadChildren: () => import('@app/view/profile/profile.module').then(m => m.ProfileModule) },
      { path: ':id/edit', loadChildren: () => import('@app/view/profile/profile.module').then(m => m.ProfileModule) }
    ]
  },
  { path: 'users', component: UsersComponent, canActivate: [AutoLoginPartialRoutesGuard, AuthGuard] },
  {
    path: 'user',
    pathMatch: 'prefix',
    canActivate: [AutoLoginPartialRoutesGuard, AuthGuard],
    children: [
      { path: '', loadChildren: () => import('@app/view/user/user.module').then(m => m.UserModule) },
      { path: ':id', loadChildren: () => import('@app/view/user/user.module').then(m => m.UserModule) },
      { path: ':id/edit', loadChildren: () => import('@app/view/user/user.module').then(m => m.UserModule) }
    ]
  },
  { path: 'error', component: ErrorComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', component: ErrorComponent, canActivate: [AutoLoginPartialRoutesGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
