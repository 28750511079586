<textarea
  matInput
  [attr.required]="required"
  formControlName="{{ formCtrlName }}"
  class="ontario-input ontario-textarea"
  [class.ontario-input__error]="hasError"
  id="{{ formCtrlId }}"
  name="{{ formCtrlName }}"
  aria-describedby="describedBy"
  aria-invalid="hasError"></textarea>
<ng-content></ng-content>
