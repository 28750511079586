import { AUTH } from "@/src/environments/environment";
import { APP_REQUEST_CONTEXT } from "@/src/shared/loading";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private httpClient: HttpClient) { }

  isServiceAuthorized(loginEmail:string, isAppRequest?: boolean) {
    const { IS_SERVICE_AUTHORIZED, SERVICE_NAME } = AUTH;
    const options: any = {};
    if (loginEmail) {
      if (isAppRequest) options.context = APP_REQUEST_CONTEXT;
      const endpoint = IS_SERVICE_AUTHORIZED.replace(`{email}`, loginEmail).replace(`{service}`, SERVICE_NAME);
      return (this.httpClient.get(endpoint, options) as unknown as Observable<string[]>);
    } else
      return of([]);
  }
}