import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { ReferenceData, ServerReferenceData } from './app.constants';
import { HttpClient } from '@angular/common/http';
import { APP_REQUEST_CONTEXT } from '@shared/loading';
import { API_ENDPOINTS } from '@/src/environments/environment';
import { ServerAuthorizedServiceModel } from './auth';

export const transformer = {
  toReferenceData: (rd: ServerReferenceData, i: number): ReferenceData => ({
    id: rd?.code,
    name: { en: rd?.value, fr: rd?.valueFr },
    displayOrder:  i + 1
  })
};

@Injectable({
  providedIn: 'root'
})
export class AppDataLookupService {

  constructor(private httpClient: HttpClient) {}

  getRankOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_DATA_RANK, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getTitleOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_DATA_TITLE, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getServiceRoleOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_DATA_ROLE, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
       map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getApplicationRoleOptions(): Observable<ServerAuthorizedServiceModel[]> {
    return (this.httpClient.get(API_ENDPOINTS.USER_APP_ROLES, { context: APP_REQUEST_CONTEXT }) as Observable<ServerAuthorizedServiceModel[]>)
  }

  getAgencyOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_DATA_AGENCY, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getRegionOptions(): Observable<ReferenceData[]> {
    return (this.httpClient.get(API_ENDPOINTS.REF_DATA_REGION, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>).pipe(
      map(rds => rds.map(transformer.toReferenceData))
    );
  }

  getAllReferenceData() : Observable<ServerReferenceData[]> { 
    return (this.httpClient.get(API_ENDPOINTS.REF_DATA_ALL, { context: APP_REQUEST_CONTEXT }) as Observable<ServerReferenceData[]>);
  }
}