<div
  class="ontario-alert ontario-alert--success"
  [class.ontario-alert--informational]="isInfoAlert"
  [class.ontario-alert--warning]="isWarningAlert"
  [class.ontario-alert--success]="isSuccessAlert"
  [class.ontario-alert--error]="isErrorAlert">
  <div class="ontario-alert__header">
    <div class="ontario-alert__header-icon">
      <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
        <use *ngIf="isInfoAlert" href="#ontario-icon-alert-informational"></use>
        <use *ngIf="isWarningAlert" href="#ontario-icon-alert-warning"></use>
        <use *ngIf="isSuccessAlert" href="#ontario-icon-alert-success"></use>
        <use *ngIf="isErrorAlert" href="#ontario-icon-alert-error"></use>
      </svg>
    </div>
    <h2 class="ontario-alert__header-title ontario-h4">
      <ng-content select="[title]"></ng-content>
    </h2>
  </div>

  <div class="ontario-alert__body">
    <ng-content select="[body]"></ng-content>
  </div>
</div>
