import { AUTH, API_DOMAIN } from '@/src/environments/environment';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, mergeMap, of, switchMap, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: OidcSecurityService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith(AUTH.AUTHORITY_ISSUER)) {
      // Modify headers for GET requests
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${AUTH.BASIC_TOKEN}`,
          'X-OAUTH-IDENTITY-DOMAIN-NAME': AUTH.REQ_HEADER_DOMAIN_NAME
        }
      });
    }

    // For future integration with police portal API
    return this.authService.getAccessToken().pipe(
      mergeMap(token => {
        if((request.url.startsWith(API_DOMAIN)) && token) {
          request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
          });
        }

        // Pass the modified request to the next interceptor or the HTTP handler
        // return next.handle(modifiedRequest);
        return next.handle(request).pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
              // directly remove 'key_ops' from http response.
              if (typeof event.body?.keys?.map === 'function' ){ 
                event.body.keys.map(k => {
                  if (k.hasOwnProperty('key_ops')) {
                    delete k['key_ops'];
                  }
                  return k;
                });
              }
            }
          })
        );
      })
    );
  }
}