import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'odsf-radio',
  templateUrl: './odsf-radio.component.html',
  styleUrls: ['./odsf-radio.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class OdsfRadio implements OnInit {
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() formCtrlId: string;
  @Input() formCtrlName: string;
  @Input() formCtrl: FormControl;
  @Input() optionItems: string[];
  @Input() values: any[];
  @Input() inOneLine: boolean = true;
  @Input() radioValue: boolean;
  
  constructor() {
    
  }

  ngOnInit(): void {
    if (this.inOneLine == null || this.inOneLine == undefined) this.inOneLine = true;
  }

  get hasError() {
    return this.formCtrl?.invalid;
  }

  get describedBy() {
    return this.hasError ? `${this.formCtrlName}-field-error` : null;
  }
}
