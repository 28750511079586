import { HttpConstants } from "./http.constant";

const HTTP_STATUS = HttpConstants.STATUS as any;
const HTTP_STATUS_CODE = HttpConstants.CODE as any;

interface ServerResponseJson {
  success: boolean;
  statusCode: number;
  statusName: string;
  data?: unknown;
  message?: string[] | string;
}

export class ServerResponse {
  res: any; // ServerResponse from json-server
  statusCode: number;
  errorMessages: string[];

  constructor(res: unknown, statusCode: number, errorMessages: string[]) {
    this.res = res;
    this.statusCode = statusCode ?? (res as any).statusCode;
    this.errorMessages = errorMessages ?? [];
  }

  setStatusCode(code: number) {
    this.statusCode = code ?? this.statusCode;
    return this;
  }

  setErrorMessages(messages: string[]) {
    this.errorMessages = messages?.length ? messages : this.errorMessages;
    return this;
  }

  toErrorJson(): ServerResponseJson {
    return {
      success: false,
      statusCode: this.statusCode,
      statusName: HTTP_STATUS[this.statusCodeId]?.name,
      message: this.errorMessages && this.errorMessages?.length ? this.errorMessages : HTTP_STATUS[this.statusCodeId]?.description
    };
  }

  toSuccessJson(): ServerResponseJson {
    return {
      success: true,
      statusCode: this.statusCode,
      statusName: HTTP_STATUS[this.statusCodeId]?.name,
      data: this.res.locals.data
    };
  }

  get isSuccessful(): boolean {
    return !this.statusCode || (this.statusCode && this.statusCode < HTTP_STATUS.BadRequest.code);
  }

  get statusCodeId(): string {
    return HTTP_STATUS_CODE[this.statusCode];
  }
}
