import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';

const mods = [ClickOutsideDirective];

@NgModule({
  declarations: [...mods],
  exports: [...mods]
})
class DomListenersModule {}

export { ClickOutsideDirective, DomListenersModule };
