import { AbstractControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ValidatorType } from '@app/core/models';

function badgeNumberRequiredIf (control: AbstractControl): ValidationErrors | null {
  const form = control.parent as FormGroup;
  if (form && form.get('hasBadge') && form.get('hasBadge').value) {
    return !control.value ? { required: true } : null;
  }
  return null;
};

function requiredIf (fieldName: string) {
  return function (control: AbstractControl): ValidationErrors | null {
    const form = control.parent as FormGroup;
    if (form && form.get(fieldName) && form.get(fieldName).hasOwnProperty('value')) {
      return !control.value ? { required: true } : null;
    }
    return null;
  };
}

function requiredIfValue (fieldName: string, value: any, propertyName? : any) {
  return function (control: AbstractControl): ValidationErrors | null {
    const form = control.parent as FormGroup;
    if (form && form.get(fieldName) && form.get(fieldName).hasOwnProperty('value') 
    && (propertyName? form.get(fieldName).value?.some(obj => obj[propertyName]===value) : form.get(fieldName).value === value)) {
      return !control.value ? { required: true } : null;
    }
    return null;
  };
}

export const UserSchema: any = {
  id: {
    id: 'id',
    name: 'id',
    type: Number,
    db: true,
    form: false,
    defaultValue: null,
    disabled: false,
    label: '',
    description: '',
    validators: []
  },
  userId: {
    id: 'user-id',
    name: 'userId',
    type: String,
    db: false,
    form: false,
    defaultValue: null,
    disabled: false,
    label: '',
    description: '',
    validators: []
  },
  firstName: {
    id: 'first-name',
    name: 'firstName',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.firstName.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  lastName: {
    id: 'last-name',
    name: 'lastName',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.lastName.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  loginEmail: {
    id: 'login-email',
    name: 'loginEmail',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.agency.email.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  rank: {
    id: 'rank',
    name: 'rank',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.rank.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  titleCode: {
    id: 'title-code',
    name: 'titleCode',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.titleCode.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  serviceRole: {
    id: 'service-role',
    name: 'serviceRole',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.role.name',
    description: '',
    validators: [{
      type: ValidatorType.Required,
      fn: Validators.required,
      message: 'validation.message.enter.a'
    },
    {
      type: ValidatorType.MaxLength,
      fn: Validators.maxLength(50),
      value: 50,
      message: 'validation.message.maxLength'
    }]
  },
  eswRole: {
    id: 'esw-role',
    name: 'eswRole',
    type: String,
    db: false,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.eswRole.name',
    description: '',
    validators: []
  },
  eswRoleCode: {
    id: 'esw-role-code',
    name: 'eswRoleCode',
    type: String,
    db: false,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.eswRoleCode.name',
    description: '',
    validators: []
  },
  hasBadge: {
    id: 'has-badge',
    name: 'hasBadge',
    type: Boolean,
    db: true,
    form: true,
    defaultValue: false,
    disabled: false,
    label: 'userForm.field.hasBadge.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  agencyType: {
    id: 'agency-type',
    name: 'agencyType',
    type: Boolean,
    db: false,
    form: true,
    defaultValue: false,
    disabled: false,
    label: 'userForm.field.agencyType.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  badgeNumber: {
    id: 'badge-number',
    name: 'badgeNumber',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'userForm.field.badgeNumber.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredIf,
        fn: requiredIf('hasBadge'),
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(36),
        value: 36,
        message: 'validation.message.maxLength'
      }
    ]
  },
  agency: {
    id: 'agency',
    name: 'agency',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.agency.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: requiredIfValue('serviceRole', 2, 'id'),
        message: 'validation.message.select.a'
      }
    ]
  },
  
  courtRegionCode: {
    id: 'court-region-code',
    name: 'courtRegionCode',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: false,
    label: 'userForm.field.courtRegionCode.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: requiredIfValue('serviceRole', 2, 'id'),
        message: 'validation.message.select.a'
      }
    ]
  },
  isSeconded: {
    id: 'is-seconded',
    name: 'isSeconded',
    type: Boolean,
    db: true,
    form: true,
    defaultValue: false,
    disabled: false,
    label: 'userForm.field.isSeconded.name',
    description: '',
    validators: [
      {
        type: ValidatorType.Required,
        fn: Validators.required,
        message: 'validation.message.enter.a'
      }
    ]
  },
  secondedAgencyCode: {
    id: 'seconded-agency-code',
    name: 'secondedAgencyCode',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'userForm.field.secondedAgencyCode.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredIf,
        fn: requiredIfValue('isSeconded', true),
        message: 'validation.message.enter.a'
      }
    ]
  },
  secondedEmail: {
    id: 'seconded-agency-code',
    name: 'secondedEmail',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'userForm.field.secondedEmail.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredIf,
        fn: requiredIfValue('isSeconded', true),
        message: 'validation.message.enter.a'
      }
    ]
  },
  isActive: {
    id: 'is-active',
    name: 'isActive',
    type: Boolean,
    db: true,
    form: true,
    defaultValue: true,
    disabled: false,
    label: 'userForm.field.isActive.label',
    description: '',
    validators: []
  },
  deactivationReason: {
    id: 'deactivation-reason',
    name: 'deactivationReason',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'userForm.field.deactivationReason.name',
    description: '',
    validators: [
      // {
      //   type: ValidatorType.RequiredIf,
      //   fn: requiredIfValue('isActive', false),
      //   message: 'validation.message.enter.a'
      // }
    ]
  },
  readOnly: {
    id: 'read-only',
    name: 'readOnly',
    type: Boolean,
    db: true,
    form: true,
    defaultValue: false,
    disabled: false,
    label: 'userForm.field.readOnly.name',
    description: '',
    validators: []
  },
  readOnlyReason: {
    id: 'read-only-reason',
    name: 'readOnlyReason',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'userForm.field.readOnlyReason.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredIf,
        fn: requiredIfValue('readOnly', true),
        message: 'validation.message.enter.a'
      }
    ]
  },
  receiveNotification: {
    id: 'receive-notification',
    name: 'receiveNotification',
    type: Boolean,
    db: true,
    form: true,
    defaultValue: false,
    disabled: false,
    label: 'userForm.field.receiveNotification.name',
    description: '',
    validators: [
    ]
  },
  notificationEmail: {
    id: 'notification-email',
    name: 'notificationEmail',
    type: String,
    db: true,
    form: true,
    defaultValue: null,
    disabled: true,
    label: 'notification email',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredIf,
        fn: requiredIfValue('receiveNotification','true'),
        message: 'validation.message.enter.a'
      },
      {
        type: ValidatorType.MaxLength,
        fn: Validators.maxLength(50),
        value: 50,
        message: 'validation.message.maxLength'
      }
    ]
  },
  declare: {
    id: 'declare',
    name: 'declare',
    type: Boolean,
    db: true,
    form: true,
    defaultValue: false,
    disabled: false,
    label: 'userForm.field.declare.name',
    description: '',
    validators: [
      {
        type: ValidatorType.RequiredTrue,
        fn: Validators.requiredTrue,
        message: 'validation.message.must.select.true'
      }
    ]
  }
};