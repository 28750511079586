<div *ngIf="moduleReady">
  <ods-spinner *ngIf="displaySpinner">
    {{ "app.spinner.text" | translate | async }}
  </ods-spinner>

  <div [class.ontario-navigation--open]="topNav?.isNavOpened">
    <app-top-nav #topNav title="{{ 'app.title' | translate | async }}" shortTitle="{{ 'app.short.title' | translate | async }}" [langLabel]="langLabel" [langLabelAbbr]="nextLanguage" (onLanguageToggled)="toggleLanguage()"></app-top-nav>

    <main>
      <div *ngIf="pageReady" id="platform-content" class="ontario-row ontario-row--collapse ontario-expanded" data-sticky-container="" data-sticky-wrap="true">
        <!-- main container -->
        <article id="pjax-container" class="platform-main ontario-small-12 ontario-large-10 ontario-columns ontario-margin-bottom-0-! ontario-row ontario-padding-bottom-0-! ontario-row-without-max">
          <div class="ontario-row ontario-row-without-max">
            <div class="ontario-column ontario-column-without-padding">
              <router-outlet></router-outlet>
            </div>
          </div>
        </article>
      </div>
    </main>

    <app-footer></app-footer>

    <ods-back2top btnName="{{ 'app.backToTop.btn' | translate | async }}"></ods-back2top>
  </div>
</div>