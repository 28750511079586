import { NgModule } from '@angular/core';
import { AuthApiService } from './auth-api.service';
import { AuthConfigModule } from './auth-config.module';
import { AuthStorageService } from './auth-storage.service';
import { SessionTimeoutService } from './session-timeout.service';
import { UserApiService } from './user-api.service';
import { UserDispatcher } from './user.dispatcher';
import { ServerAuthorizedServiceModel, ServerUserModel, User, UserArguments, UserFormArguments, UserModel, userForm, withUserTransformer } from './user.model';
import { UserSchema } from './user.schema';
import { UserService } from './user.service';

const mods = [];

@NgModule({
  declarations: [...mods],
  exports: [...mods]
})
class UserModule { }

export {
  AuthConfigModule,
  AuthStorageService,
  AuthApiService,
  ServerAuthorizedServiceModel,
  ServerUserModel,
  SessionTimeoutService,
  User,
  UserApiService,
  UserArguments,
  UserDispatcher,
  UserFormArguments,
  UserModel,
  UserModule,
  UserSchema,
  UserService,
  userForm,
  withUserTransformer
};

