import { v4 as uuidv4 } from 'uuid';

export class AppSchema {
  public static readonly submission = {
    requiredFields: function (payload) {
      const requireds = ['typeOfInquiryCode', 'courtRegionCode', 'courtHouseCode', 'subject', 'message', 'declare'];
      if (payload?.typeOfInquiryCode !== "REQUEST") {
        requireds.push('packageNumber');
      }
      return requireds;
    }
  };

  public static readonly message = {
    requiredFields: function (payload) {
      const requireds = ['courtHouseCode', 'subject', 'message'];
      if (!!payload?.courtHouseCode) {
        requireds.push('packageNumber');
      }
      return requireds;
    }
  };
}

export class ServerRequest {
  req: any; // IncomingMessage

  constructor(req: unknown) {
    this.req = req as any;
  }

  validate() {
    const invalidFields = this.body.requiredFields?.filter(
      field => !this.req.body.hasOwnProperty(field) || this.req.body[field] === null
    );

    if (invalidFields?.length > 0) {
      return {
        valid: false,
        errorMessages: invalidFields.map(f => `${f}.invalid`)
      };
    }

    return { valid: true };
  }

  get body() {
    const modelName = (this.req?.originalUrl as String)?.split('/').pop();
    let requestBody: any = { dataType: modelName };

    switch (modelName) {
      case 'inquiry-submissions':
        requestBody.requiredFields = AppSchema.submission.requiredFields(this.req?.body);
        requestBody.data = {
          id: this.req?.body?.id,
          packageId: this.req?.body?.id,
          packageUUID: uuidv4(),
          packageType: "IF",
          subPackageType: null,
          currentStatus: "DELIVERED",
          sentDate: new Date().toISOString(),
          receivedDate: null,
          file: null,
          originalRequest: { ...this.req?.body }
        };
        break;
      case 'messages':
        requestBody.requiredFields = AppSchema.message.requiredFields(this.req?.body);
        requestBody.data = {
          id: this.req?.body?.id,
          messageId: this.req?.body?.id,
          sentDate: new Date().toISOString(),
          originalRequest: { ...this.req?.body }
        };
        break;
      default:
        requestBody = {};
        break;
    }

    return requestBody;
  }
}
