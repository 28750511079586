import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpConstants } from './http.constant';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((response: HttpResponse<any>) => {
        if (response instanceof HttpResponse && this.isInterceptableUrl(request.url) && response?.body?.success) {
          const res = new HttpResponse({ status: HttpConstants.STATUS.Ok.code, body: response?.body?.data });
          return res;
        }
        return response;
      })
    );
  }

  private isInterceptableUrl(url: string): boolean {
    // return [].find(v => url.toUpperCase().indexOf(v.toUpperCase()) > -1);
    return true;
  }
}
