import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ods-callout',
  templateUrl: './ods-callout.component.html',
  styleUrls: ['./ods-callout.component.scss']
})
export class OdsCalloutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
