import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/core/auth';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppService } from '@app/core';
// import { tap } from 'rxjs';
// import { OdsTable } from '@shared/components/ods';
import { AppCacheService } from '@app/core/cache';
import { LanguageService } from '@shared/language';

@Component({
  selector: 'app-officer',
  templateUrl: './officer.component.html',
  styleUrls: ['./officer.component.scss']
})

export class OfficerComponent {

  //add your list of Application here
  applicationsList = [
    {
      imgUrl: '../../../../assets/images/esw-enforcement-agency-module.png',
      title: 'officer.enrolledService.card.title.esw',
      description: 'officer.enrolledService.card.desc.esw',
      clickFunction: () => {
        this.appService.goToPolicePortal({ blank: true });
        return
      }
    }
  ];

  constructor(private router: Router,
    private authService: OidcSecurityService,
    private appService: AppService,
    private appCache: AppCacheService,
    private languageService: LanguageService,
    private userService: UserService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // if (!this.userService.loggedIn) this.router.navigate(['/home']);
    this.appService.enabledMenu = true;
    // this.userService.getAllUsers()
    //   .pipe(
    //     tap(users => {
    //       this.usersList = users.reduce((x, y) => {
    //         x.push(this.userToObj(y));
    //         return x;
    //       }, []);
    //       this.usersListTable.setDataSourceByArray(this.usersList);
    //     })
    //   ).subscribe();
  }

  // usersListColumns: string[] = ['loginEmail', 'name', 'title', 'rank', 'agency', 'region'];
  // usersList: any[] = [];
  // @ViewChild('usersListTable') usersListTable: OdsTable<any>;

  get loggedIn() {
    return this.userService.loggedIn;
  }

  get authorizedServices() {
    return this.userService.authorizedServices;
  }

  get isAuthorized() {
    return this.userService.isAuthorized;
  }

  //  goToPolicePortal() {
  //   this.appService.goToPolicePortal({ blank: true });
  // }

  goToUserManagement() {
    this.appService.goToUsersPage({ blank: true });
  }

  hasAccess(serviceName: string) {
    return this.userService.hasAccess(serviceName);
  }

  // private userToObj(user: any) {
  //   return {
  //     loginEmail: user.loginEmail,
  //     name: user.firstName + " " + user.lastName,
  //     title: user.titleCode ? (this.getValueFromCache('title', user.titleCode) ?? user.titleCode) : "",
  //     rank: user.rank ? (this.getValueFromCache('rank', user.rank) ?? user.rank) : "",
  //     agency: user.agency ? (this.getValueFromCache('agency', user.agency) ?? user.agency) : "",
  //     region: user.region ? (this.getValueFromCache('region', user.region) ?? user.region) : ""
  //   }
  // }

  private getValueFromCache(cachename: string, code: string): string {
    return this.appCache[`${cachename}Options`]?.find(x => x.id === code)?.name[this.languageService.language ?? 'en'];
  }

}
