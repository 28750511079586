export const withModel = function ({ schema, state }) {
  const toModel = function ({ formGroup }) {
    const v = formGroup.getRawValue();
    const fields = Object.keys(state);

    return fields.reduce((m, name) => {
      // use original value if no form value
      m[name] = v[name]?? state[name];
      return m;
    }, {});
  };

  const toPayload = function ({ formGroup }) {
    const model = toModel({ formGroup });
    const fields = Object.keys(model);

    return fields.reduce((m, name) => {
      // skip if field should not be stored in db
      if (!schema[name]?.db) return m;
      m[name] = model[name];
      return m;
    }, {});
  };

  return {
    toModel,
    toPayload
  };
};
