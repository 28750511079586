<input
  matInput
  [attr.required]="required"
  [readonly]="disabled"
  formControlName="{{ formCtrlName }}"
  class="{{hidden?'hidden-input':'ontario-input'}}"
  type="text"
  [class.ontario-input__error]="hasError"
  id="{{ formCtrlId }}"
  name="{{ formCtrlName }}"
  value="{{value}}"
  aria-describedby="describedBy"
  aria-invalid="hasError"/>
