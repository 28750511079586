import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'odsf-cards',
  templateUrl: './odsf-cards.component.html',
  styleUrls: ['./odsf-cards.component.scss']
})
export class OdsfCardsComponent implements OnInit  {
  @Input() dataList: any[];
  @Input() onClick: () => void;
  constructor() {}

  ngOnInit(): void {}

  // handleClick(clickFunction: string, item: any): void {
  //   if (clickFunction && eval(clickFunction)) {
  //     eval(clickFunction)(item);
  //   }
  // }
}
