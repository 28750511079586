import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'odsf-input',
  templateUrl: './odsf-input.component.html',
  styleUrls: ['./odsf-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class OdsfInput implements OnInit {
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() formCtrlId: string;
  @Input() formCtrlName: string;
  @Input() formCtrl: FormControl;
  @Input() value: string;
  @Input() hidden: boolean = false;
  
  constructor() {}

  ngOnInit(): void {}

  get hasError() {
    return this.formCtrl?.touched && this.formCtrl?.invalid;
  }

  get describedBy() {
    return this.hasError ? `${this.formCtrlName}-field-error` : null;
  }
}
