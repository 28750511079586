import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCoreModule } from '@app/core';
import { SharedModule } from '@shared/modules';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';

const components = [ProfileComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ProfileRoutingModule, AppCoreModule, SharedModule],
  exports: [...components]
})
export class ProfileModule {}
