import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppConstants, AppService } from '@app/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UserService } from '@app/core/auth';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppCacheService } from '@app/core/cache';
@Component({
  selector: 'app-top-nav',
  templateUrl: './app-top-nav.component.html',
  styleUrls: ['./app-top-nav.component.scss']
})
export class AppTopNavComponent implements OnInit {
  @ViewChild('mainNav') mainNav: ElementRef;
  @ViewChild('openMenuBtn') openMenuBtn: ElementRef;
  @ViewChild('closeMenuBtn') closeMenuBtn: ElementRef;

  @Input() title: string;
  @Input() shortTitle: string;
  @Input() langLabel: string;
  @Input() langLabelAbbr: string;
  @Output() onLanguageToggled = new EventEmitter();

  searchEnabled = false;
  menuEnabled = false;
  i18nEnabled = true;
  isNavOpened = false;

  constructor(private authService: OidcSecurityService,
    private appService: AppService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private appCache: AppCacheService) {
    this.observeScreenSize();
  }

  ngOnInit(): void {
    // this.appService.enabledMenu$.subscribe(enabled => {
    //   this.menuEnabled = enabled;
    // });
  }

  get loggedIn() {
    return this.userService.loggedIn;
  }

  get userEmail() {
    return this.userService.userEmail;
  }

  get userId() {
    return this.userService.userId;
  }

  get isAuthorized() {
    return this.userService.isAuthorized;
  }

  get authorizedServices() {
    return this.userService.authorizedServices;
  }

  get hasAdminAccess() {
    return this.authorizedServices.some(role => (role.id < 4))
  }

  get hasEnrolledServicesAccess() {
    return this.authorizedServices.some(role => (role.id >= 4))
  }

  get isNotBusinessUser(){
    return this.authorizedServices.every(role => (role.id != 1))

  }

  get userAgency() {
    const agency = this.appCache[`agencyOptions`]
      .filter(agency => parseInt(agency.id) == parseInt(this.userService.userAgency));
    return agency.length ? agency[0].name.en : '';
  }

  private observeScreenSize(): void {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ]).subscribe(result => {
      this.menuEnabled = result.matches;
    });
  }

  toggleLanguage() {
    this.onLanguageToggled.emit();
  }

  toggleNav(action?) {
    if (action) {
      this.isNavOpened = action === AppConstants.NAV_ACTION.OPEN;
    } else {
      this.isNavOpened = !this.isNavOpened;
    }
    return;
  }

  logout() {
    this.userService.logOffAndRevokeAccess()
      .subscribe();
  }

  displayAgencyName() {
    if (this.loggedIn && this.authorizedServices.some(role => role.id !== 1))
      return true;
    return false;
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    if (!this.isNavOpened) return;
    this.toggleNav(AppConstants.NAV_ACTION.CLOSE);
  }

  @HostListener('document:click', ['$event.target']) onClick(target) {
    if (!this.isNavOpened) return;

    const menuElems = [this.mainNav, this.openMenuBtn, this.closeMenuBtn];
    const clickedOutside = menuElems.every(elem => !elem.nativeElement.contains(target));
    if (clickedOutside) {
      this.toggleNav(AppConstants.NAV_ACTION.CLOSE);
    }
  }
}
