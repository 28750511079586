<footer class="ontario-footer ontario-footer--default ontario-margin-top-0-!">
  <div class="ontario-row">
    <div class="ontario-columns ontario-small-12">
      <ul class="ontario-footer__links-container ontario-footer__links-container--inline">
        <li><a class="ontario-footer__link" href="https://www.ontario.ca/page/accessibility">{{ 'app.footer.accessibility.a' | translate | async }}</a></li>
        <li><a class="ontario-footer__link" href="https://www.ontario.ca/page/privacy-statement">{{ 'app.footer.privacy.a' | translate | async }}</a></li>
        <li><a class="ontario-footer__link" href="#">{{ 'app.footer.contactUs.a' | translate | async }}</a></li>
      </ul>
      <div class="ontario-footer__copyright">
        <a class="ontario-footer__link" href="https://www.ontario.ca/page/copyright-information" innerHtml="{{ 'app.footer.copyright.a' | translate | async }}"></a>
      </div>
    </div>
  </div>
</footer>
