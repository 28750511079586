import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/core';
import { UserService } from '@app/core/auth';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent {

  constructor(private userService: UserService, private appService: AppService, private router: Router) { }

  ngOnInit() {
    const logoutUrl = localStorage.getItem('postLogoutRedirectUri');
    this.userService.loggedIn$.pipe(
      switchMap(loggedIn => {
        loggedIn ?
        this.userService.logOffAndRevokeAccess() :
        logoutUrl ? this.router.navigate([logoutUrl]) : this.appService.goToHomePage()
        localStorage.removeItem('postLogoutRedirectUri')
        return of(false);
      })
    ).subscribe();
  }

  get loggedIn() {
    return this.userService.loggedIn;
  }
}
