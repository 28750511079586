import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { AuthGuard } from '@shared/guard/auth-guard';
import { UserComponent } from './user.component';

const routes: Routes = [{ path: '', component: UserComponent, canActivate: [AutoLoginPartialRoutesGuard, AuthGuard] }];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class UserRoutingModule {}
