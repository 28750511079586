import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification, NotificationModel, NotificationType } from '@app/core/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private _list: NotificationModel[] = [];
  private subject: BehaviorSubject<NotificationModel[]> = new BehaviorSubject(this._list);
  private _list$: Observable<NotificationModel[]> = this.subject.asObservable();

  constructor(private httpClient: HttpClient) {}

  add(n: NotificationModel): Observable<NotificationModel[]> {
    this.list = cloneDeep(this._list).concat(n);
    return this._list$;
  }

  addError(details: NotificationModel): Observable<NotificationModel[]> {
    return this.add(Notification({ ...details, type: NotificationType.Error }));
  }

  addSuccess(details: NotificationModel): Observable<NotificationModel[]> {
    return this.add(Notification({ ...details, type: NotificationType.Success }));
  }

  clear(): Observable<NotificationModel[]> {
    this.list = [];
    return this._list$;
  }

  clearErrors(): Observable<NotificationModel[]> {
    this.list = this._list.filter(n => n?.type !== NotificationType.Error);
    return this._list$;
  }

  get list(): NotificationModel[] {
    return this._list;
  }

  set list(ns: NotificationModel[]) {
    this._list = ns;
    this.subject.next(this._list);
  }

  get list$(): Observable<NotificationModel[]> {
    return this._list$;
  }
}
