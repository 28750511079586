import { Component, DestroyRef, Injector, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { catchError, distinctUntilChanged, forkJoin, of, switchMap, tap } from 'rxjs';
import { AppService } from '@app/core';
import { UserService } from '@app/core/auth';
import { AppTopNavComponent } from '@app/core/components';
import { BaseViewComponent } from '@app/view';
import { Language } from '@shared/language';
import { LoadingService } from '@shared/loading';
import { Preference } from '@shared/preference';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseViewComponent implements OnInit {
  @ViewChild(AppTopNavComponent) topNav: AppTopNavComponent;

  title = '';
  pageReady = false;
  buildVersion = 'BUILDVERSION';
  displaySpinner = false;
  preference: Preference;

  moduleReady = false;

  #destroyRef = inject(DestroyRef);

  constructor(
    injector: Injector,
    private loadingService: LoadingService,
    private appService: AppService,
    private authService: OidcSecurityService,
    private userService: UserService
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this._initLoadingStatusChange();
    this.loadPublicResources()
      .pipe(
        tap(() => this.moduleReady = true),
        switchMap(() => this.authService.checkAuth().pipe(
          tap(res => this.userService.setLoginInfo(res)),
          tap(() => this.userService.isAuthenticated().pipe(
            distinctUntilChanged(),
            tap((res) => this.userService.setAuthorizedService(res)),
            tap(() => this.userService.setUserRoles().subscribe()),
            tap(() => this.loadPrivateResources().subscribe()),
            catchError((error) => {
              // Handle the error
              console.error('An error occurred:', error);
              if (error.status === 401) {
                localStorage.setItem('postLogoutRedirectUri', '/unauthorized');

                this.userService.logOffAndRevokeAccess().subscribe()
              }
              return of(false);
            })
          ).subscribe())
        )),
        tap(() => this.renderApp()),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe(() => this.updateView());
  }

  private loadPublicResources() {
    const resources = [
      this.appService.loadI18n()
    ];
    return forkJoin(resources);
  }

  private loadPrivateResources() {
    const resources = [
      this.userService.loggedIn ? this.appService.createAPICache(this.buildVersion) : of(null)
    ];
    return forkJoin(resources);
  }

  protected override updateView() {
    return this.changeDetectorRef.markForCheck();
  }

  private renderApp() {
    this.appCache.buildVersion = this.buildVersion;
    this.loadingService.stopLoading();
    this.pageReady = true;
    this.appService.pageReady = true;
  }

  toggleLanguage() {
    const language = this.nextLanguage;
    this.appService.setUserPrefLang(language).pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(() => this.updateView());
  }

  private _initLoadingStatusChange() {
    this.loadingService.loading$.pipe(
      distinctUntilChanged(),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((loading) => {
      this.displaySpinner = loading;
      this.changeDetectorRef.detectChanges();
    });
  }

  get nextLanguage() {
    const preference = this.appCache.preference;
    return preference?.language === Language.English ? Language.French : Language.English;
  }

  get langLabel() {
    return this.appCache?.[this.nextLanguage]?.[this.nextLanguage];
  }
}
