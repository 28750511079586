<!-- <div>
    <ul class="ontario-card__container ontario-card--cards-per-row-2">
        <li
            class="ontario-card ontario-card--image--one-third   ontario-card--position-horizontal ontario-card--position-horizontal__image-left">
            <div class="ontario-card__image-container">
                <a href="#">
                    <img class="ontario-card__image" src="../../../../assets/images/esw-enforcement-agency-module.png"
                        alt="">
                </a>
            </div>
            <div class="ontario-card__text-container ontario-card--image-true">
                <h2 class="ontario-card__heading">
                    <a href="#">
                        Card title
                    </a>
                </h2>
                <div class="ontario-card__description">
                    <p>Write a call-to-action and concise description about a single topic.</p>
                </div>
            </div>
        </li>
    </ul>
</div> -->

<div *ngIf="dataList.length > 0">
    <ul class="ontario-card__container ontario-card--cards-per-row-2" >
        <li *ngFor="let row of dataList" (click)="row.clickFunction()" (keypress)="row.clickFunction()"
            class="ontario-card ontario-card--image--one-fourth   ontario-card--position-horizontal ontario-card--position-horizontal__image-left" tabindex="0">
            <div class="ontario-card__image-container">
                <img class="ontario-card__image img-center" [src]="row.imgUrl" alt="">
            </div>
            <div class="ontario-card__text-container ontario-card--image-true">
                <h2 class="ontario-card__heading">
                        {{row.title | translate | async }}
                </h2>
                <div class="ontario-card__description">
                    <p>{{row.description | translate | async }}</p>
                </div>
            </div>
        </li>
    </ul>
</div>