import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable, map, take, tap } from 'rxjs';
import { UserService } from '@/src/app/core/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private userService: UserService, private router: Router) {}

  canActivate: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> => {
    // Check the user's authentication status
    return this.userService.loggedIn$.pipe(
      take(1),
      map(loggedIn => !!loggedIn),
      tap(loggedIn => {
        // Redirect to login page if not logged in
        if (!loggedIn) {
          this.router.navigate(['/login']);
        }
      }),
      map(loggedIn => {
        // Allow navigation if logged in
        if (loggedIn) {
          const isUserAllowed = this.userService.authorizedServices.some(role => role.id < 4);
          // Return true if user has allowed roles, otherwise redirect to error page
          return isUserAllowed || this.router.createUrlTree(['/error']);
        }
        // If not logged in, block navigation
        return loggedIn;
      })
    );
  }
}