export class HttpConstants {
  public static readonly METHOD = {
    GET: {
      id: 'GET',
      name: 'GET',
      description: ''
    },
    POST: {
      id: 'POST',
      name: 'POST',
      description: ''
    },
    UPDATE: {
      id: 'UPDATE',
      name: 'UPDATE',
      description: ''
    },
    PATCH: {
      id: 'PATCH',
      name: 'PATCH',
      description: ''
    },
    DELETE: {
      id: 'DELETE',
      name: 'DELETE',
      description: ''
    }
  };

  public static readonly STATUS = {
    Ok: {
      id: 'Ok',
      code: 200,
      name: 'Ok',
      description: 'The request is OK'
    },
    BadRequest: {
      id: 'BadRequest',
      code: 400,
      name: 'Bad Request',
      description: 'The request cannot be fulfilled due to bad syntax'
    },
    Unauthorized: {
      id: 'Unauthorized',
      code: 401,
      name: 'Unauthorized',
      description:
        'The request was a legal request, but the server is refusing to respond to it. For use when authentication is possible but has failed or not yet been provided'
    },
    PaymentRequired: {
      id: 'PaymentRequired',
      code: 402,
      name: 'Payment Required',
      description: 'Reserved for future use'
    },
    Forbidden: {
      id: 'Forbidden',
      code: 403,
      name: 'Forbidden',
      description: 'The request was a legal request, but the server is refusing to respond to it'
    },
    NotFound: {
      id: 'NotFound',
      code: 404,
      name: 'Not Found',
      description: 'The requested page could not be found but may be available again in the future'
    },
    MethodNotAllowed: {
      id: 'MethodNotAllowed',
      code: 405,
      name: 'Method Not Allowed',
      description: 'A request was made of a page using a request method not supported by that page'
    },
    NotAcceptable: {
      id: 'NotAcceptable',
      code: 406,
      name: 'Not Acceptable',
      description: 'The server can only generate a response that is not accepted by the client'
    },
    ProxyAuthenticationRequired: {
      id: 'ProxyAuthenticationRequired',
      code: 407,
      name: 'Proxy Authentication Required',
      description: 'The client must first authenticate itself with the proxy'
    },
    RequestTimeout: {
      id: 'RequestTimeout',
      code: 408,
      name: 'Request Timeout',
      description: 'The server timed out waiting for the request'
    },
    Conflict: {
      id: 'Conflict',
      code: 409,
      name: 'Conflict',
      description: 'The request could not be completed because of a conflict in the request'
    },
    Gone: {
      id: 'Gone',
      code: 410,
      name: 'Gone',
      description: 'The requested page is no longer available'
    },
    LengthRequired: {
      id: 'LengthRequired',
      code: 411,
      name: 'Length Required',
      description: 'The "Content-Length" is not defined. The server will not accept the request without it '
    },
    PreconditionFailed: {
      id: 'PreconditionFailed',
      code: 412,
      name: 'Precondition Failed',
      description: 'The precondition given in the request evaluated to false by the server'
    },
    RequestTooLarge: {
      id: 'RequestTooLarge',
      code: 413,
      name: 'Request Too Large',
      description: 'The server will not accept the request, because the request entity is too large'
    },
    RequestURITooLong: {
      id: 'RequestURITooLong',
      code: 414,
      name: 'Request-URI Too Long',
      description:
        'The server will not accept the request, because the URI is too long. Occurs when you convert a POST request to a GET request with a long query information '
    },
    UnsupportedMediaType: {
      id: 'UnsupportedMediaType',
      code: 415,
      name: 'Unsupported Media Type',
      description: 'The server will not accept the request, because the media type is not supported '
    },
    RangeNotSatisfiable: {
      id: 'RangeNotSatisfiable',
      code: 416,
      name: 'Range Not Satisfiable',
      description: 'The client has asked for a portion of the file, but the server cannot supply that portion'
    },
    ExpectationFailed: {
      id: 'ExpectationFailed',
      code: 417,
      name: 'Expectation Failed',
      description: 'The server cannot meet the requirements of the Expect request-header field'
    },
    InternalServerError: {
      id: 'InternalServerError',
      code: 500,
      name: 'Internal Server Error',
      description: 'A generic error message, given when no more specific message is suitable'
    },
    NotImplemented: {
      id: 'NotImplemented',
      code: 501,
      name: 'Not Implemented',
      description: 'The server either does not recognize the request method, or it lacks the ability to fulfill the request'
    },
    BadGateway: {
      id: 'BadGateway',
      code: 502,
      name: 'Bad Gateway',
      description: 'The server was acting as a gateway or proxy and received an invalid response from the upstream server'
    },
    ServiceUnavailable: {
      id: 'ServiceUnavailable',
      code: 503,
      name: 'Service Unavailable',
      description: 'The server is currently unavailable (overloaded or down)'
    },
    GatewayTimeout: {
      id: 'GatewayTimeout',
      code: 504,
      name: 'Gateway Timeout',
      description: 'The server was acting as a gateway or proxy and did not receive a timely response from the upstream server'
    },
    HTTPVersionNotSupported: {
      id: 'HTTPVersionNotSupported',
      code: 505,
      name: 'HTTP Version Not Supported',
      description: 'The server does not support the HTTP protocol version used in the request'
    },
    NetworkAuthenticationRequired: {
      id: 'NetworkAuthenticationRequired',
      code: 511,
      name: 'Network Authentication Required',
      description: 'The client needs to authenticate to gain network access'
    }
  };

  public static readonly CODE = {
    200: 'Ok',
    400: 'BadRequest',
    401: 'Unauthorized',
    402: 'PaymentRequired',
    403: 'Forbidden',
    404: 'NotFound',
    405: 'MethodNotAllowed',
    406: 'NotAcceptable',
    407: 'ProxyAuthenticationRequired',
    408: 'RequestTimeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'LengthRequired',
    412: 'PreconditionFailed',
    413: 'RequestTooLarge',
    414: 'RequestURITooLong',
    415: 'UnsupportedMediaType',
    416: 'RangeNotSatisfiable',
    417: 'ExpectationFailed',
    500: 'InternalServerError',
    501: 'NotImplemented',
    502: 'BadGateway',
    503: 'ServiceUnavailable',
    504: 'GatewayTimeout',
    505: 'HTTPVersionNotSupported',
    511: 'NetworkAuthenticationRequired'
  };
}