export class Utils {
  /**
   * format tokenized text
   * e.g text = 'I am {0} {1}'
   * params = ['John', 'Smith']
   * output 'I am John Smith'
   */
  static format(text: string, params: any[]): string {
    if (params && params.length > 0) {
      params.forEach((param, index) => {
        text = text?.replace(new RegExp('\\{' + index + '\\}', 'g'), param);
      });
      return text;
    }
    return text;
  }

  static saveBase64ToFile(base64String: string, fileName: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.download = fileName;
    anchor.href = url;
    anchor.click();

    window.URL.revokeObjectURL(url);
  }
}
