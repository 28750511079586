import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppComponent } from '@app/app.component';
import { AuthConfigModule, AuthStorageService } from '@app/core/auth';
import { CacheInterceptor } from '@app/core/cache';
import { AuthInterceptor, CorsInterceptor } from '@app/core/http';
import { LoadingInterceptor } from '@shared/loading';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

import { AppDataLookupService } from './app-data-lookup.service';
import { AppRoutingModule } from './app-routing.module';
import { AppConstants } from './app.constants';
import { AppService } from './app.service';
import { I18nModule } from './i18n';
import { Utils } from './utils';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const angularFormModules = [FormsModule, ReactiveFormsModule];
const materialModules = [
  MatTooltipModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSortModule,
  MatPaginatorModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatIconModule
];
const otherAppModules = [I18nModule];

const mods = [...angularFormModules, ...materialModules, ...otherAppModules];

@NgModule({
  imports: [HttpClientModule, AuthConfigModule, ...mods],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: AbstractSecurityStorage, useClass: AuthStorageService }
  ],
  exports: [...mods]
})
class AppCoreModule { }

export { AppComponent, AppConstants, AppCoreModule, AppDataLookupService, AppRoutingModule, AppService, Utils };
