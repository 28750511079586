import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCoreModule } from '@app/core';
import { SharedModule } from '@shared/modules';
import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const components = [UserComponent];

@NgModule({
  declarations: [...components],
  imports: [MatSelectModule,CommonModule, FormsModule, ReactiveFormsModule, RouterModule, UserRoutingModule, AppCoreModule, SharedModule, MatSlideToggleModule ],
  exports: [...components]
})
export class UserModule {}
