import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';

const mods = [TranslatePipe];

@NgModule({
  declarations: [...mods],
  exports: [...mods]
})
class I18nModule {}

export { I18nModule, TranslatePipe };
