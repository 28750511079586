import { environment } from "@/src/environments/environment";
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "@app/core";
import {
  onUserFormErrorsChanged$,
  onUserFormHasBadgeChanged$,
  onUserFormIsActiveChanged$,
  onUserFormIsSecondedChanged$,
  onUserFormReadOnlyChanged$,
  onUserFormSuccessChanged$,
  onUserFormViewChanged$,
} from "@app/core/app.subscriptions";
import {
  ServerAuthorizedServiceModel,
  UserApiService,
  UserDispatcher,
  UserService,
  userForm,
} from "@app/core/auth";
import {
  BaseViewComponent,
  PageView,
  getViewFromUrl,
} from "@app/view/base-view.component";
import isEmpty from "lodash/isEmpty";
import { delay, map, takeWhile } from "rxjs";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent extends BaseViewComponent implements OnInit {
  private paginator: MatPaginator;

  @ViewChild("paginator", { static: false }) set matPg(
    paginator: MatPaginator
  ) {
    if (paginator) {
      this.paginator = paginator;
      this.dataSource.paginator = this.paginator;
    }
  }
  private sort: MatSort;
  @ViewChild("sort", { static: false }) set matSt(sort: MatSort) {
    if (sort) {
      this.sort = sort;
      this.dataSource.sort = this.sort;
    }
  }

  user: any = { form: {} };
  notifications: any = {};

  isAccordionOpened = false;
  lowestRoleId;
  displayedColumns = ["modifiedDate", "action", "modifiedBy", "modifiedValues"];
  badgeRadio = [true, false]
  agencyTypeRadio = ["home", "secondment"]
  loadingResults = true;
  resultsLength = 0;
  dataSource = new MatTableDataSource<any>();
  emptyData = new MatTableDataSource<any>([{ empty: "row" }]);
  enrolledServicesList = [];
  appServicesList = [];
  selectedApp = [];
  enrolledRoles = [];
  editUserRole = []
  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private dispatcher: UserDispatcher,
    private appService: AppService,
    private userApiService: UserApiService,
    private userService: UserService
  ) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.lowestRoleId = Math.min(...this.userService.authorizedServices.map(role => role.id));
    this.appServicesList = this.appRolesGroupList(this.optionItems('appRole'))
    console.log(this.appServicesList)
    const route = this.route,
      router = this.router,
      dispatcher = this.dispatcher;


    onUserFormViewChanged$({ route, router, dispatcher })
      .pipe(takeWhile(() => this.alive))
      .subscribe((user: any) => {
        console.log("user:", user);
        this.user = user;
        const applications = this.user.form.get('serviceRole').value?.filter(role => role.serviceCode !== 'CJDD')
        if (this.user.form.get('serviceRole').value?.filter(role => role.roleCode === 'basic') && applications.length)
          this.addServices(applications)
        dispatcher.clearNotifications();

        this.formCtrl("hasBadge").value === 'true'
          ? this.formCtrl("badgeNumber").enable()
          : this.formCtrl("badgeNumber").disable();
        if (this.isActiveDisabled)
          this.formCtrl("isActive").disable()
        this.register$([
          onUserFormHasBadgeChanged$(user.form),
          onUserFormIsSecondedChanged$(user.form),
          onUserFormIsActiveChanged$(user.form),
          onUserFormReadOnlyChanged$(user.form),
          onUserFormErrorsChanged$(dispatcher.notifications$, (ns) => {
            this.notifications.error = ns[0];
          }),
          onUserFormSuccessChanged$(dispatcher.notifications$, (ns) => {
            if (!isEmpty(ns)) this.appService.goToUsersPage();
          }),
        ]);

        this.updateView();

      });

    this.route.paramMap.subscribe((params) => {
      if (params?.get("id")) {
        let id: string = params?.get("id");
        this.userApiService
          .getUserHistory(id)
          .pipe(map((data) => this.transformHistoryRecord(data)))
          .subscribe((data) => {
            this.updateUserTable(data);
          });
      }
    });
  }

  private updateUserTable(data?) {
    console.log("user history data: ", data);
    this.loadingResults = false;
    if (data && data.hasOwnProperty("length")) {
      this.resultsLength = data.length;
      this.dataSource.data = [...data];
    }
  }

  transformHistoryRecord(data): any {
    return data.reduce((a, o) => {
      var key =
        o.action + o.tableName + o.recordId + o.modifiedBy + o.modifiedDate;
      var b = a.find(
        (x) =>
          x.action +
          x.tableName +
          x.recordId +
          x.modifiedBy +
          x.modifiedDate ===
          key
      );
      if (b) {
        b.modifiedValues = b.modifiedValues ?? [];
        b.modifiedValues.push({
          fieldName: o.fieldName,
          newValue: o.newValue,
          oldValue: o.oldValue,
        });
      } else {
        o.modifiedValues = [
          {
            fieldName: o.fieldName,
            newValue: o.newValue,
            oldValue: o.oldValue,
          },
        ];
        var m = {
          action: o.action,
          tableName: o.tableName,
          recordId: o.recordId,
          modifiedBy: o.modifiedBy,
          modifiedDate: o.modifiedDate,
          modifiedValues: o.modifiedValues,
        };
        a.push(m);
      }
      return a;
    }, []);
  }

  addServices(roles?) {
    if (roles?.length) {
      roles.forEach(obj => {
        this.selectedApp.push(obj.serviceCode)
        this.enrolledServicesList.push({ appOptions: this.appServicesList, roleCode: this.appServicesList.find(role => role.serviceCode === obj.serviceCode)?.roleCode || [], selectedValue: obj.serviceCode, selectedRoleId: obj.id })
      });

    }
    else
      this.enrolledServicesList.push({ appOptions: this.appServicesList, roleCode: [], selectedValue: null, selectedRoleId: null })
  }

  onAppChange(event, app, index) {
    console.log(event, 'event')
    this.enrolledServicesList[index].selectedValue = event.value;
    this.enrolledServicesList[index].roleCode = this.appServicesList?.find(app => app?.serviceCode === event.value)?.roleCode
    this.selectedApp.push(event.value)
  }

  onAppRoleAdded(event, index) {
    this.enrolledServicesList[index].selectedRoleId = event.value;
  }

  handleRoleSelectionChange(event){
    console.log(event,'event')
    if (this.lowestRoleId === 1 && !event?.value?.some(obj => obj.id === 2)) {
      this.formCtrl('serviceRole').reset(); // Reset the form control to deselect all options
      console.log(this.user.form.get('serviceRole').value)
    }
  }

  sendForm() {
    Object.keys(this.user.form.controls).forEach(key => {
      const control = this.user.form.get(key);
      control.markAsTouched();
    });
    
    let payload = this.user.toPayload();
    payload.serviceRole = payload.serviceRole.filter(r => r.serviceCode === 'CJDD')
    // payload.notificationEmail = payload.loginEmail;
    payload.certEmail = payload.loginEmail;
    payload.badgeNumber = this.user.form.get('hasBadge').value === 'true' ? payload.badgeNumber : '';
    if (payload.serviceRole?.some(role => role.id === 4))
      this.enrolledServicesList?.forEach(r => {
        const role = this.optionItems('appRole')?.find(obj => obj.id === r.selectedRoleId)
        if (role?.id)
          payload.serviceRole.push(this.optionItems('appRole')?.find(obj => obj.id === r.selectedRoleId))
      })
    if (this.lowestRoleId !== 1) {
      payload.agency = this.userService.userAgency;
      payload.courtRegionCode = this.userService.userRegion;
    }

    console.log("send payload", payload);
    if (this.canSubmit) {
      return this.dispatcher
        .save(payload, { from: this.router.url, msg: payload?.id ? 'notification.user.update.success.message' : 'notification.user.create.success.message' }, true)
        .pipe(
          takeWhile(() => this.alive),
          delay(2000)
        )
        .subscribe();
    }
    return this.user;

  }

  clearForm() {
    this.user = userForm({ validateForm: environment.validateForm });
  }

  deleteApp(index: number, selectedValue) {

    this.enrolledServicesList.splice(index)
    this.selectedApp = this.selectedApp.filter(value => value !== selectedValue)
  }

  printForm() {
    setTimeout(() => {
      window.print();
    }, 100);
  }

  appRolesGroupList(rolesList) {
    const groupedRolesMap = new Map();
    
    rolesList?.forEach(role => {
        const { serviceCode, description } = role;
        
        // Initialize an empty array for each serviceCode if not already present
        if (!groupedRolesMap.has(serviceCode)) {
            groupedRolesMap.set(serviceCode, []);
        }
        
        // Push role object into the array for the corresponding serviceCode
        groupedRolesMap.get(serviceCode).push(role);
    });

    // Convert grouped roles into desired format
    const resultList = Array.from(groupedRolesMap.entries()).map(entry => {
        return {
            serviceCode: entry[0],
            roleCode: entry[1],
            description: entry[1][0].description // Assuming description is unique for each serviceCode
        };
    });
    return resultList;
}



  optionItems(name) {
    return this.appCache[`${name}Options`];
  }

  formCtrl(name) {
    return this.user?.form?.get(name) as FormControl;
  }

  field(name) {
    return this.user?.schema && this.user?.schema[name];
  }

  goToUsersPage() {
    this.appService.goToUsersPage();
  }

  get viewType() {
    return getViewFromUrl(this.router.url);
  }

  get isFormView() {
    return this.viewType === PageView.Create;
  }

  get isReadOnlyView() {
    return this.viewType === PageView.Read;
  }

  get isEditView() {
    return this.viewType === PageView.Update;
  }

  get shouldShowErrors() {
    return this.notifications?.error;
  }

  get canSubmit() {
    return this.user.validateForm
      ? this.user?.form?.valid && !this.user?.form?.pristine
      : true;
  }

  get isActiveDisabled() {
    return this.user.form.get('serviceRole').value?.some(obj => obj.id <= this.lowestRoleId)
  }

  compareWithFn(modelValue, optionValue) {
    return modelValue && optionValue ? modelValue.id === optionValue.id : modelValue === optionValue;
  }

  filteredApps(app, index) {
    const options = this.appServicesList.filter(obj => obj.serviceCode === app.selectedValue || !this.selectedApp?.find(servicecode => obj.serviceCode === servicecode)) || []
    return this.enrolledServicesList[index].appOptions = options;
  }

  filteredRoles(name) {

    return this.optionItems(name).filter(option => {
      if (this.lowestRoleId === 1) {
        // If any role has id 1, return option with id 2
        return Number(option.id) === 2 || Number(option.id) === 4;
      } else {
        // If no role has id 1, return options with id greater than lowestRoleId
        return Number(option.id) > this.lowestRoleId;
      }
    });
  }

  isOptionEnabled(role: any): boolean {
    const selectedValues =  this.user.form.get('serviceRole').value;
    return role.id === '4' && this.lowestRoleId === 1 && !this.user.form.get('serviceRole').value?.some(obj => obj.id === 2)
  }

  displayEnrolledApp(serviceRole) {
    return serviceRole?.some(role => role.roleCode === 'Basic' || role.id >= 4)
  }
}
