import { AUTH } from '@/src/environments/environment';
import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: AUTH.AUTHORITY_ISSUER,
        redirectUrl: `${window.location.origin}/`,
        postLoginRoute: '/home',
        postLogoutRedirectUri: `${window.location.origin}/logout`,
        clientId: AUTH.CLIENT_ID,
        scope: 'openid profile email offline_access',
        responseType: 'code',
        silentRenew: true,
        maxIdTokenIatOffsetAllowedInSeconds: 6000,
        issValidationOff: true,
        autoUserInfo: false,
        useRefreshToken: true,
        historyCleanupOff: true,
        logLevel: LogLevel.Debug,
        customParamsAuthRequest: {
          domain: AUTH.REQ_HEADER_DOMAIN_NAME,
        },
        customParamsEndSessionRequest: {
          domain: AUTH.REQ_HEADER_DOMAIN_NAME,
        }
      }
    })
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }