import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/core';
import { UserService } from '@app/core/auth';
import { BaseViewComponent } from '../base-view.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends BaseViewComponent implements OnInit {
  constructor(private injector: Injector, private userService: UserService, private appService: AppService) {
    super(injector);
  }
  loggedIn = false

  override ngOnInit(): void {
    super.ngOnInit();
    this.userService.loggedIn$.subscribe(loggedIn => {
      this.loggedIn = loggedIn
      if (loggedIn && this.userService.authorizedServices.length)
        this.router.navigate([this.userService.authorizedServices.some(role => role.id < 4) ? '/users' : '/officer']);
    }
    )
  }
}
