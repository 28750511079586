import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCellDef, MatColumnDef, MatHeaderRowDef, MatRowDef, MatTable, MatTableDataSource, } from '@angular/material/table';

@Component({
  selector: 'ods-table',
  templateUrl: './ods-table.component.html',
  styleUrls: ['./ods-table.component.scss']
})
export class OdsTable<T> {
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ContentChildren(MatCellDef) cellDefs: QueryList<MatCellDef>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;

  @Input() columns: string[];
  @Input() dataSourceByArray: any[];
  @Input() columnsTitle: string[];
  @Input() tableCaption: string;
  @Input() showFilter: boolean = false;
  @Input() showPaginator: boolean = false;

  dataSource: MatTableDataSource<T>;
  displayedColumns: string[] = [];

  @Output() load = new EventEmitter();
  @Output() updateView = new EventEmitter();

  @Output() actionAddClick = new EventEmitter();
  @Output() actionEditClick = new EventEmitter();
  @Output() actionDeleteClick = new EventEmitter();
  @Output() actionDownloadClick = new EventEmitter();
  @Output() actionUploadClick = new EventEmitter();

  loading = true;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.dataSourceByArray);
    this.displayedColumns = this.columns;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setDataSourceByArray(dbArray: any[]) {
    this.dataSourceByArray = dbArray;
    this.dataSource = new MatTableDataSource(this.dataSourceByArray);
  }

  addAnRowToTable(row: any) {
    this.dataSource.data.push(row);
    this.dataSource._updateChangeSubscription();
  }

  deleteAnRowFromTable(row: any): boolean {
    const rid = this.dataSource.data.indexOf(row);
    if (rid >= 0) {
      this.dataSource.data.splice(rid, 1);
      this.dataSource._updateChangeSubscription();
      return true;
    } else {
      return false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  performActionAdd(row: any) {
    if (this.actionAddClick) this.actionAddClick.emit(row);
  }

  performActionEdit(row: any) {
    if (this.actionEditClick) this.actionEditClick.emit(row);
  }

  performActionDelete(row: any) {
    if (this.actionDeleteClick) this.actionDeleteClick.emit(row);
  }

  performActionDownload(row: any) {
    if (this.actionDownloadClick) this.actionDownloadClick.emit(row);
  }

  performActionUpload(row: any) {
    if (this.actionUploadClick) this.actionUploadClick.emit(row);
  }

  includeButtonsOrNot(str: string): boolean {
    return /^\[.*\]$/.test(str);
  }
}
