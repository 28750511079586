import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private _loading: boolean = true;
  private subject = new BehaviorSubject<boolean>(this._loading);
  private _loading$: Observable<boolean> = this.subject.asObservable();

  constructor() {}

  get loading(): boolean {
    return this._loading;
  }

  set loading(loading: boolean) {
    this._loading = loading;
    this.subject.next(this._loading);
  }

  get loading$(): Observable<boolean> {
    return this._loading$;
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
}
