import { Injectable } from '@angular/core';
import { Preference } from '@shared/preference';
import { Language } from '@shared/language';
import { environment } from '@environments/environment';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AppCacheService {
  initPreference() {
    if (!this.preference) {
      this.preference = new Preference(environment.defaultLanguage as Language);
    }
  }

  setTimestamp() {
    this.ts = new Date().getTime();
  }

  setHash() {
    this.hash = 0;
  }

  setPreferenceLanguage(language) {
    const preference = this.preference;
    preference.language = language;
    this.preference = preference;
  }

  private cacheDurationInHours() {
    const ts = moment(this.ts);
    const now = moment();
    return moment.duration(now.diff(ts)).asHours();
  }

  /**
   * this is the assumption we're making from now, if no timestamp or hash set
   * we assume cache is not loaded yet
   */
  get empty() {
    return !this.ts || !this.hash;
  }

  /**
   * cache expries in 12 hours
   */
  get expired() {
    const hours = this.cacheDurationInHours();
    return hours > environment.cacheLifespanInHours;
  }

  /**
   * hash is calculated base on the concatenation of
   * all api cache names. If a new cache is available,
   * this hash will change and force a reload
   */
  get adding() {
    return true;
  }

  set preference(preference: Preference) {
    localStorage.setItem('user/preference', JSON.stringify(preference));
  }

  get preference() {
    const pref = localStorage.getItem('user/preference');
    return pref ? JSON.parse(pref.toString()) : undefined;
  }

  get en() {
    const enFile = localStorage.getItem('en.json');
    return enFile ? JSON.parse(enFile.toString()) : undefined;
  }

  set en(en: any) {
    localStorage.setItem('en.json', JSON.stringify(en));
  }

  get fr() {
    const frFile = localStorage.getItem('fr.json');
    return frFile ? JSON.parse(frFile.toString()) : undefined;
  }

  set fr(fr: any) {
    localStorage.setItem('fr.json', JSON.stringify(fr));
  }

  get ts() {
    const ts = localStorage.getItem('ts');
    return ts ? JSON.parse(ts.toString()) : undefined;
  }

  set ts(ts: any) {
    localStorage.setItem('ts', JSON.stringify(ts));
  }

  get hash() {
    const hash = localStorage.getItem('hash');
    return hash ? JSON.parse(hash.toString()) : undefined;
  }

  set hash(hash: any) {
    localStorage.setItem('hash', JSON.stringify(hash));
  }

  get buildVersion() {
    const buildVersion = localStorage.getItem('buildVersion');
    return buildVersion ? JSON.parse(buildVersion.toString()) : undefined;
  }

  set buildVersion(buildVersion: any) {
    localStorage.setItem('buildVersion', JSON.stringify(buildVersion));
  }

  // get userServiceRoleOptions() {
  //   const userServiceRoleOptions = localStorage.getItem('userServiceRoleOptions');
  //   return userServiceRoleOptions ? JSON.parse(userServiceRoleOptions.toString()) : undefined;
  // }

  // set userServiceRoleOptions(userServiceRoleOptions: any) {
  //   localStorage.setItem('userServiceRoleOptions', JSON.stringify(userServiceRoleOptions));
  // }

  get rankOptions() {
    const rankOptions = localStorage.getItem('rankOptions');
    return rankOptions ? JSON.parse(rankOptions.toString()) : undefined;
  }

  set rankOptions(rankOptions: any) {
    localStorage.setItem('rankOptions', JSON.stringify(rankOptions));
  }

  get titleOptions() {
    const titleOptions = localStorage.getItem('titleOptions');
    return titleOptions ? JSON.parse(titleOptions.toString()) : undefined;
  }

  set titleOptions(titleOptions: any) {
    localStorage.setItem('titleOptions', JSON.stringify(titleOptions));
  }

  get serviceRoleOptions() {
    const serviceRoleOptions = localStorage.getItem('serviceRoleOptions');
    return serviceRoleOptions ? JSON.parse(serviceRoleOptions.toString()) : undefined;
  }

  set serviceRoleOptions(serviceRoleOptions: any) {
    localStorage.setItem('serviceRoleOptions', JSON.stringify(serviceRoleOptions));
  }

  get appRoleOptions() {
    const appRoleOptions = localStorage.getItem('applicationRoleOptions');
    return appRoleOptions ? JSON.parse(appRoleOptions.toString()) : undefined;
  }

  set appRoleOptions(appRoleOptions: any) {
    localStorage.setItem('applicationRoleOptions', JSON.stringify(appRoleOptions));
  }

  get agencyOptions() {
    const agencyOptions = localStorage.getItem('agencyOptions');
    return agencyOptions ? JSON.parse(agencyOptions.toString()) : undefined;
  }

  set agencyOptions(agencyOptions: any) {
    localStorage.setItem('agencyOptions', JSON.stringify(agencyOptions));
  }


  get regionOptions() {
    const regionOptions = localStorage.getItem('regionOptions');
    return regionOptions ? JSON.parse(regionOptions.toString()) : undefined;
  }

  set regionOptions(regionOptions: any) {
    localStorage.setItem('regionOptions', JSON.stringify(regionOptions));
  }

}
