import { AUTH } from '@/src/environments/environment';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, EMPTY, Observable, of, tap } from 'rxjs';
import { AuthApiService, UserApiService, UserModel } from '.';

@Injectable({ providedIn: 'root' })
export class UserService {
  private _list: any[] = [];
  private listSubject = new BehaviorSubject(this._list);
  private _list$: Observable<any[]> = this.listSubject.asObservable();

  private _loginUser: UserModel = null;
  private loginUserSubject = new BehaviorSubject<UserModel>(this._loginUser);
  private _loginUser$: Observable<UserModel> = this.loginUserSubject.asObservable();

  private _roles = [];
  private rolesSubject = new BehaviorSubject<string[]>([]);
  private _roles$ = this.rolesSubject.asObservable();
  private _authorizedServices = [];
  private authorizedServicesSubject = new BehaviorSubject<any[]>([]);
  private _authorizedServices$ = this.authorizedServicesSubject.asObservable();
  private _loggedIn = false;
  private loggedInSubject = new BehaviorSubject<boolean>(null);
  private _loggedIn$ = this.loggedInSubject.asObservable();
  private _userEmail = '';
  private userEmailSubject = new BehaviorSubject<string>('');
  private _userEmail$ = this.userEmailSubject.asObservable();
  private _userAgency = '';
  private userAgencySubject = new BehaviorSubject<string>(this._userAgency);
  private _userAgency$ = this.userEmailSubject.asObservable();
  private _userRegion = '';
  private userRegionSubject = new BehaviorSubject<string>(this._userRegion);
  private _userRegion$ = this.userEmailSubject.asObservable();
  private _userId = '';
  private userIdSubject = new BehaviorSubject<string>(this._userId);
  private _userId$ = this.userIdSubject.asObservable();

  constructor(private authApiService: AuthApiService, private authService: OidcSecurityService, private userApiService: UserApiService) { }

  add(item) {
    this.list = cloneDeep(this._list).concat(item);
    return this._list$;
  }

  remove(id) {
    this.list = cloneDeep(this._list).filter(s => s?.id !== id);
    return this._list$;
  }

  get list() {
    return this._list;
  }

  set list(list: any[]) {
    this._list = list;
    this.listSubject.next(this._list);
  }

  get list$() {
    return this._list$;
  }

  get loginUser(): UserModel {
    return this._loginUser;
  }

  set loginUser(item: UserModel) {
    this._loginUser = item;
    this.loginUserSubject.next(this._loginUser);
  }

  get loginUser$(): Observable<UserModel> {
    return this._loginUser$;
  }

  get roles() {
    return this._roles;
  }

  set roles(data: any) {
    this._roles = data;
    this.rolesSubject.next(this._roles);
  }

  get roles$() {
    return this._roles$;
  }

  get authorizedServices() {
    return this._authorizedServices;
  }

  set authorizedServices(data: any) {
    this._authorizedServices = data;
    this.authorizedServicesSubject.next(this._authorizedServices);
  }

  get authorizedServices$() {
    return this._authorizedServices$;
  }

  get loggedIn() {
    return this._loggedIn;
  }

  set loggedIn(data: any) {
    this._loggedIn = data;
    this.loggedInSubject.next(this._loggedIn);
  }

  get loggedIn$() {
    return this._loggedIn$;
  }

  get userEmail() {
    return this._userEmail;
  }

  set userEmail(data: any) {
    this._userEmail = data;
    this.userEmailSubject.next(this._userEmail);
  }

  get userEmail$() {
    return this._userEmail$;
  }

  get userId() {
    return this._userId;
  }

  set userId(data: any) {
    this._userId = data;
    this.userIdSubject.next(this._userId);
  }

  get userId$() {
    return this._userId$;
  }

  get userAgency() {
    return this._userAgency;
  }

  set userAgency(data: any) {
    this._userAgency = data;
    this.userAgencySubject.next(this._userAgency);
  }

  get userAgency$() {
    return this._userAgency$;
  }

  get userRegion() {
    return this._userRegion;
  }

  set userRegion(data: any) {
    this._userRegion = data;
    this.userRegionSubject.next(this._userRegion);
  }

  get userRegion$() {
    return this._userRegion$;
  }

  get isAuthorized() {
    return this.roles && this.roles.length;
  }

  hasAccess(serviceName: string) {
    return this.authorizedServices?.filter((s: any) => s.serviceCode === serviceName)?.length > 0;
  }

  revokeAccess() {
    this.loginUser = Object.assign({});
    this.roles = [];
    this.authorizedServices = [];
    this.loggedIn = false;
    this.userEmail = '';
    localStorage.removeItem(`0-${AUTH.CLIENT_ID}`);
  }

  logOffAndRevokeAccess() {
    return this.authService.logoff().pipe(tap(() => this.revokeAccess()));
  }

  setLoginInfo({ isAuthenticated, userData, accessToken }) {
    console.log('app authenticated', isAuthenticated);
    console.log(`Current access token is '${accessToken}'`);
    console.log('user data: ', userData);
    // this.loggedIn = isAuthenticated;
    this.userEmail = isAuthenticated ? userData?.email || null : null;
  }

  isAuthenticated() {
    return this.userApiService.getByEmail(this.userEmail, true)
  }

  setUserRoles() {
    return this.userEmail ?
      this.authApiService.isServiceAuthorized(this.userEmail, true)
        .pipe(tap(roles => { console.log('user roles', roles); this.roles = roles; }))
      : of(null);
  }

  setAuthorizedService(user) {
    if (!user?.isActive){
      localStorage.setItem('postLogoutRedirectUri', '/unauthorized');
      this.logOffAndRevokeAccess().subscribe();
      return
    }
    console.log('user id', user?.id); this.userId = user?.id;
    console.log('user agency', user?.agency); this.userAgency = user?.agency;
    console.log('user Region', user?.courtRegionCode); this.userRegion = user?.courtRegionCode;
    console.log('authorized services', user?.serviceRole); this.authorizedServices = user?.serviceRole;
    this.loggedIn = true
    console.log(this.loggedIn, "loggedIn")
  }

  getAllUsers(): Observable<any> {
    return this.userEmail ?
      this.userApiService.getAllAccountByCertEmail(this.userEmail, true)
        .pipe(tap((users: any) => {
          console.log('linked users', users);
        }))
      : of(null);
  }
}
